import { DropdownItemsProps } from '../../../components/Dropdown/Dropdown';

export const JESSICA_SERVICES: DropdownItemsProps[] = [
  {
    placeholder: 'First Time Consult',
    value: 'First Time Consult',
  },
  {
    placeholder: 'General Consult',
    value: 'General Consult',
  },
  {
    placeholder: 'Hormone Replacement',
    value: 'Hormone Replacement',
  },
  {
    placeholder: 'Functional Medicine',
    value: 'Functional Medicine',
  },
  {
    placeholder: 'Platelet Rich Plasma',
    value: 'Platelet Rich Plasma',
  },
  {
    placeholder: 'Stem Cells',
    value: 'Stem Cells',
  },
  {
    placeholder: 'Neural Therapy',
    value: 'Neural Therapy',
  },
  {
    placeholder: 'Vitamin Infusion',
    value: 'Vitamin Infusion',
  },
];
