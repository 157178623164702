import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import WhatISImage from '../../../assets/images/unique/Vitamin Infusion/vitamin.jpeg';

export const VitaminInfusionParagraphs: ParagraphProps[] = [
  {
    title: 'What is vitamin infusion?',
    text: `Vitamin infusion, also known as intravenous (IV) vitamin therapy or vitamin drip, is a medical procedure in which a mixture of vitamins, minerals, and other nutrients is delivered directly into a patient's bloodstream through an intravenous line. This method allows for the rapid and efficient absorption of nutrients, bypassing the digestive system.
    \n The composition of the infusion can vary based on the specific needs of the patient and the goals of the treatment. Common components of vitamin infusions may include: vitamins, minerals, amino acids, electrolytes.
    \n The purported benefits of vitamin infusions include:`,
    img: WhatISImage,
  },
  {
    title: 'Improved Nutrient Absorption',
    text: `By delivering nutrients directly into the bloodstream, the body may absorb a higher percentage of the vitamins and minerals compared to oral supplementation.`,
    headingLevel: 'h4',
  },
  {
    title: 'Increased Energy Levels',
    text: `Some people report increased energy and vitality after receiving vitamin infusions, particularly if they were deficient in certain nutrients.`,
    headingLevel: 'h4',
  },
  {
    title: 'Immune System Support',
    text: `Vitamin C, in particular, is believed to support the immune system, and vitamin infusions are sometimes used for immune system support.`,
    headingLevel: 'h4',
  },
  {
    title: 'Recovery from Illness or Stress',
    text: `Athletes and individuals recovering from illness or intense physical stress may use vitamin infusions to support their recovery.`,
    headingLevel: 'h4',
  },
  {
    text: 'In some cases, vitamin infusions may be recommended for individuals with specific medical conditions or nutrient deficiencies. The infusion should be administered by a licensed healthcare provider in a medical setting to ensure safety and proper monitoring.',
  },
];
