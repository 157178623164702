import { ReactElement, useEffect, useState } from 'react';
import styles from './Stars.module.scss';
import { Icon } from '../../../Icon';

interface StarsProps {
  rating: number;
}

export const Stars = ({ rating }: StarsProps) => {
  const RATING_FLOOR: number = Math.floor(rating);
  const DECIMALS: number = rating - RATING_FLOOR;

  const [stars, setStars] = useState<ReactElement[]>([]);

  useEffect(() => {
    const starsHelper: ReactElement[] = [];
    for (let i = 0; i < RATING_FLOOR; i += 1) {
      starsHelper.push(
        <Icon
          key={Math.random().toString()}
          classname={styles.root}
          type="star"
          alt="rating"
        />
      );
    }
    if (DECIMALS > 0.2 && DECIMALS < 0.7) {
      starsHelper.push(
        <Icon key={Math.random().toString()} type="star-half" alt="rating" />
      );
    }
    if (DECIMALS > 0.7) {
      starsHelper.push(
        <Icon key={Math.random().toString()} type="star" alt="rating" />
      );
    }

    setStars(starsHelper);
  }, []);

  const renderStars = () => {
    return stars;
  };
  return <div className={styles.root}>{renderStars()}</div>;
};
