/* eslint-disable react/no-unknown-property */
import styles from './MapTag.module.scss';
import { Icon } from '../../../Icon/Icon';
import { Button } from '../../../Button/Button';
import { ColorPalettes } from '../../LandingBlock/LandingBlock';
import GoogleMapsImage from '../../../../assets/images/general/google-maps-logo.png';

interface MapTagProps {
  direction: string;
  lat: number;
  lng: number;
  googleLink: string;
  colorPalette?: ColorPalettes;
}

export const MapTag = ({
  direction,
  lat,
  lng,
  colorPalette = 'doctor-1',
  googleLink,
}: MapTagProps) => {
  const onCopy = () => {
    navigator.clipboard.writeText(direction);
  };

  const onGoogle = () => {
    window.open(googleLink);
  };
  return (
    <div className={styles.root}>
      <Icon type="location" alt="Location" />
      <div className={styles.direction}>
        <div className={styles.text}>{direction}</div>
        <Button
          className={styles.copy}
          text="Copy"
          btnStyle="tertiary"
          onClick={onCopy}
        />
        <Button
          className={styles.google}
          text="Copy"
          btnStyle="tertiary"
          onClick={onGoogle}
        >
          <img src={GoogleMapsImage} alt="Google Maps" />
        </Button>
      </div>
    </div>
  );
};
