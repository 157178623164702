import cn from 'classnames';
import styles from './Paragraph.module.scss';
import { Heading, HeadingLevel } from '../../Heading/Heading';

type ImageSide = 'left' | 'right';

interface ParagraphConfiguration {
  imageAlwaysOnBottom?: boolean;
}

export interface ParagraphProps {
  img?: string;
  alt?: string;
  text?: string;
  imageSide?: ImageSide;
  title?: string;
  headingLevel?: HeadingLevel;
  configuration?: ParagraphConfiguration;
}

export const Paragraph = ({
  img = null,
  alt = 'This is your image',
  text = 'Put your text here',
  imageSide = 'left',
  title = null,
  headingLevel = 'h3',
  configuration: { imageAlwaysOnBottom } = {
    imageAlwaysOnBottom: true,
  },
}: ParagraphProps) => {
  const image = (isTabletDown: boolean) => (
    <div
      className={cn(
        styles.image,
        imageSide === 'left' && styles.left,
        imageSide === 'right' && styles.right,
        isTabletDown ? styles['tablet-down'] : styles['tablet-up']
      )}
    >
      <img src={img} alt={alt} />
    </div>
  );

  const paragraph = <p className={cn(styles.paragraph)}>{text}</p>;

  const renderParagraph = () => {
    switch (imageSide) {
      case 'left':
        return (
          <>
            {img && image(false)}
            {paragraph}
            {img && image(true)}
          </>
        );
      case 'right':
        return (
          <>
            {img && image(false)}
            {paragraph}
            {img && image(true)}
          </>
        );
      default:
        return (
          <>
            {img && image}
            {paragraph}
          </>
        );
    }
  };

  return (
    <div
      className={cn(styles.root, imageSide === 'left' && styles['image-left'])}
    >
      <Heading
        className={cn(styles.title)}
        headingLevel={headingLevel}
        headingDecoration="left"
      >
        {title}
      </Heading>
      {renderParagraph()}
    </div>
  );
};
