import { forwardRef, ReactElement } from 'react';
import cn from 'classnames';
import styles from './LandingStartBlock.module.scss';
import { Heading } from '../../Heading/Heading';
import { Button, ButtonProps } from '../../Button/Button';
import { LandingBlock, LandingBlockProps } from '../LandingBlock/LandingBlock';

export interface LandingStartBlockProps extends LandingBlockProps {
  title: string;
  subtitle?: string;
  localChildren?: ReactElement;
  button1?: ButtonProps;
  button2?: ButtonProps;
}

export const LandingStartBlock = forwardRef<
  HTMLElement,
  LandingStartBlockProps
>(
  (
    {
      title,
      button1 = null,
      button2 = null,
      subtitle,
      localChildren,
      ...props
    }: LandingStartBlockProps,
    ref
  ) => {
    const renderButtons = (isSmall: boolean = false) => {
      const cnButton = isSmall
        ? 'landing-button-small'
        : 'landing-button-large';
      return (
        <>
          {button1 && (
            <Button
              className={cn(styles[cnButton])}
              btnStyle="primary"
              {...button1}
            />
          )}
          {button2 && (
            <Button
              className={cn(styles[cnButton], styles.b)}
              btnStyle="secondary"
              {...button2}
            />
          )}
        </>
      );
    };

    return (
      <LandingBlock classname={styles.block} {...props} ref={ref}>
        <div className={styles.root}>
          <div className={styles.top}>
            <div className={styles.left}>
              <Heading
                className={cn(styles.title, !subtitle && styles['only-title'])}
                headingLevel="h2"
              >
                {title}
              </Heading>
              {subtitle && (
                <Heading className={styles.subtitle} headingLevel="h3">
                  {subtitle}
                </Heading>
              )}
              {renderButtons()}
            </div>
            <div className={styles.right}>{localChildren}</div>
          </div>
          <div className={styles.bottom}>{renderButtons(true)}</div>
        </div>
      </LandingBlock>
    );
  }
);

LandingStartBlock.displayName = 'LandingStartBlock';
