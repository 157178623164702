import { LandingBlock, LandingBlockProps } from '../LandingBlock/LandingBlock';
import styles from './LandingReviews.module.scss';
import { Heading } from '../../Heading/Heading';
import { DrJessicaReviews } from './Reviews';
import { ReviewCard } from './ReviewCard/ReviewCard';

interface LandingReviewProps extends Omit<LandingBlockProps, 'documentTitle'> {}

export const LandingReviews = ({ ...props }: LandingReviewProps) => {
  const renderReviews = () =>
    DrJessicaReviews.map((review) => (
      <ReviewCard key={review.review} {...review} />
    ));

  return (
    <LandingBlock {...props} documentTitle="Reviews" classname={styles.root}>
      <>
        <Heading className={styles.title} headingLevel="h2">
          Our reviews
        </Heading>
        <ul className={styles.container}>{renderReviews()}</ul>
      </>
    </LandingBlock>
  );
};
