import React, { ReactElement } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';
import styles from './TextArea.module.scss';

export interface TextAreaProps {
  placeholder?: string;
  name: string;
  register: UseFormRegisterReturn<string>;
  errorMessage: string | null;
  classname?: string;
  children?: React.ReactElement;
  isRequired?: boolean;
  label?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  placeholder,
  name,
  register,
  errorMessage,
  classname,
  children,
  isRequired,
  label = undefined,
}) => {
  return (
    <div
      className={cn(
        styles.root,
        errorMessage ? styles.error : undefined,
        classname
      )}
    >
      {label && (
        <label htmlFor={name}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      <textarea id={name} {...register} placeholder={placeholder} style={{}} />
      {children && children}
      {errorMessage && <span>{errorMessage as unknown as ReactElement}</span>}
    </div>
  );
};

TextArea.defaultProps = {
  placeholder: 'value',
  classname: '',
  children: null,
  isRequired: false,
};
