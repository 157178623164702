/* eslint-disable react/no-unknown-property */
import { ColorPalettes } from '../Landing/LandingBlock/LandingBlock';
import styles from './Footer.module.scss';

interface FooterProps {
  colorPalette: ColorPalettes;
}

export const Footer = ({ colorPalette }: FooterProps) => {
  return (
    <footer color-palette={colorPalette} className={styles.root}>
      <div className={styles.info}>
        Jessica P. Quintanilla Flores - Quick call: 3310504287 | Developed by
        QUIROBA Soft
      </div>
    </footer>
  );
};
