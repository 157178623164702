import React from 'react';

import cn from 'classnames';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as BookMark } from '../../assets/icons/bookmark.svg';
import { ReactComponent as Card } from '../../assets/icons/card.svg';
import { ReactComponent as Config } from '../../assets/icons/config.svg';
import { ReactComponent as Enter } from '../../assets/icons/enter.svg';
import { ReactComponent as Exit } from '../../assets/icons/exit.svg';
import { ReactComponent as Fist } from '../../assets/icons/fist.svg';
import { ReactComponent as Graph } from '../../assets/icons/graph.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Like } from '../../assets/icons/like.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Menu1 } from '../../assets/icons/menu1.svg';
import { ReactComponent as Register } from '../../assets/icons/register.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Tag } from '../../assets/icons/tag.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as Checked } from '../../assets/icons/checked.svg';
import { ReactComponent as CheckedThin } from '../../assets/icons/checked-thin.svg';
import { ReactComponent as Order } from '../../assets/icons/order.svg';
import { ReactComponent as Store } from '../../assets/icons/store.svg';
import { ReactComponent as Cart } from '../../assets/icons/cart.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as QualityMedal } from '../../assets/icons/quality_medal.svg';
import { ReactComponent as Gear } from '../../assets/icons/gear.svg';
import { ReactComponent as Diamond } from '../../assets/icons/diamond.svg';
import { ReactComponent as Precision } from '../../assets/icons/precision.svg';
import { ReactComponent as Rule } from '../../assets/icons/rule.svg';
import { ReactComponent as Rays } from '../../assets/icons/rays.svg';
import { ReactComponent as Microcopy } from '../../assets/icons/microcopy.svg';
import { ReactComponent as Whatsapp } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as Home1 } from '../../assets/icons/home1.svg';
import { ReactComponent as Medical } from '../../assets/icons/medical.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { ReactComponent as Add } from '../../assets/icons/add.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as StarHalf } from '../../assets/icons/star-half.svg';

import styles from './Icon.module.scss';

export const icons = [
  'arrow-down',
  'right-arrow',
  'arrow-up',
  'bookmark',
  'card',
  'config',
  'enter',
  'exit',
  'fist',
  'graph',
  'home',
  'like',
  'menu',
  'menu1',
  'register',
  'search',
  'tag',
  'user',
  'left-arrow',
  'cancel',
  'checked',
  'checked-thin',
  'order',
  'store',
  'cart',
  'eye',
  'quality-medal',
  'gear',
  'diamond',
  'precision',
  'rule',
  'rays',
  'microcopy',
  'whatsapp',
  'facebook',
  'home1',
  'medical',
  'location',
  'calendar',
  'info',
  'warning',
  'add',
  'star',
  'star-half',
] as const;

type IconType = (typeof icons)[number];

export interface IconProps {
  /**
   * The name of the icon to load as inline SVG
   */
  type: IconType;
  /**
   * Text that is set as the title attribute of the SVG
   */
  alt?: string;
  classname?: string;
}

export const pickIcon = (type: IconProps['type']) => {
  switch (type) {
    case 'arrow-down':
      return ArrowDown;
    case 'arrow-up':
      return ArrowUp;
    case 'bookmark':
      return BookMark;
    case 'card':
      return Card;
    case 'config':
      return Config;
    case 'enter':
      return Enter;
    case 'exit':
      return Exit;
    case 'fist':
      return Fist;
    case 'graph':
      return Graph;
    case 'home':
      return Home;
    case 'like':
      return Like;
    case 'menu':
      return Menu;
    case 'menu1':
      return Menu1;
    case 'register':
      return Register;
    case 'search':
      return Search;
    case 'tag':
      return Tag;
    case 'user':
      return User;
    case 'left-arrow':
      return LeftArrow;
    case 'right-arrow':
      return RightArrow;
    case 'cancel':
      return Cancel;
    case 'checked':
      return Checked;
    case 'checked-thin':
      return CheckedThin;
    case 'order':
      return Order;
    case 'store':
      return Store;
    case 'cart':
      return Cart;
    case 'eye':
      return Eye;
    case 'quality-medal':
      return QualityMedal;
    case 'gear':
      return Gear;
    case 'diamond':
      return Diamond;
    case 'precision':
      return Precision;
    case 'rule':
      return Rule;
    case 'rays':
      return Rays;
    case 'microcopy':
      return Microcopy;
    case 'whatsapp':
      return Whatsapp;
    case 'facebook':
      return Facebook;
    case 'home1':
      return Home1;
    case 'medical':
      return Medical;
    case 'location':
      return Location;
    case 'calendar':
      return Calendar;
    case 'info':
      return Info;
    case 'warning':
      return Warning;
    case 'add':
      return Add;
    case 'star':
      return Star;
    case 'star-half':
      return StarHalf;
    default:
      return ArrowDown;
  }
};

export const Icon = ({
  type,
  alt,
  classname,
}: IconProps): React.ReactElement => {
  const SVG = pickIcon(type);

  return <SVG className={cn(styles.icon, classname)} title={alt} />;
};
