/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import cn from 'classnames';
import styles from './Logo.module.scss';

interface LogoProps {
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';
  classname?: string;
  onClick?: () => void;
}

export const Logo = ({
  size = 'medium',
  classname = '',
  onClick = () => {},
}: LogoProps) => {
  return (
    <div
      className={cn(styles.title, styles[size], styles['no-border'], classname)}
      onClick={onClick}
    >
      <span>JIC INTERNATIONAL IMPORTADORA</span>
    </div>
  );
};
