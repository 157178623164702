import { useNavigate } from 'react-router';
import { PageRootLanding } from '../../../components/PageBuilder/PageRootLanding/PageRootLanding';

import styles from './ServicePage.module.scss';
import { JessicaArticlesCards } from '../OtherServices/JessicaArticlesCards';
import { ServiceCard } from './ServiceCard/ServiceCard';

export const ServicePage = () => {
  const navigate = useNavigate();

  const renderServices = () => {
    return JessicaArticlesCards.map((service, index) => (
      <ServiceCard
        key={service.title}
        {...service}
        classname={index === 0 && styles.newest}
        isNew={index === 0}
      />
    ));
  };

  return (
    <PageRootLanding
      colorPalettes="doctor-1"
      title="Our Services"
      navbarItems={[
        {
          endpoint: '/',
          icon: 'home1',
          name: 'Go home',
          onClick: () => {
            navigate('/');
          },
        },
      ]}
    >
      <ul className={styles.root}>{renderServices()}</ul>
    </PageRootLanding>
  );
};
