/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { Button } from '../../Button';

import styles from './SlideMenu.module.scss';
import { MenuItem } from '../../../interfaces/Menu.interfaces';
import { Icon } from '../../Icon';
import { NotificationCounter } from '../../NotificationNumber/NotificationCounter';
import { ColorPalettes } from '../../Landing/LandingBlock/LandingBlock';

interface SlideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  items: MenuItem[];
  colorPalette?: ColorPalettes;
}

export const SlideMenu = ({
  isOpen = false,
  onClose = () => {},
  items,
  colorPalette = 'unknown',
}: SlideMenuProps) => {
  const ref = useRef<HTMLDivElement>(undefined);
  const location = useLocation();
  return (
    <div
      ref={ref}
      onClick={(event) => {
        if (ref.current === event.target) {
          onClose();
        }
      }}
      className={cn(styles.background, isOpen && styles.opened)}
    >
      <div className={styles['vertical-menu']}>
        <div className={styles['close-header']}>
          <Button
            className={styles.button}
            iconPlace="only-icon"
            icon="cancel"
            onClick={() => {
              onClose();
            }}
          />
        </div>
        <ul className={styles['items-slide']}>
          {items.map((item) => {
            return (
              <li
                className={cn(
                  styles['item-slide'],
                  item.endpoint === location.pathname && styles.active
                )}
                onClick={() => {
                  onClose();
                  item.onClick();
                }}
                key={item.name}
              >
                {item?.notificationNumber > 0 && (
                  <NotificationCounter
                    classname={styles.counter}
                    number={item?.notificationNumber}
                  />
                )}
                <Icon alt={item.name} type={item.icon} />
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
