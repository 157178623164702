import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './Grid.module.scss';

interface GridProps {
  children: ReactElement[];
  gridColumns?: number;
  classname?: string;
}

export const Grid = ({
  children,
  gridColumns = 5,
  classname = '',
}: GridProps) => {
  if (gridColumns < 1 && gridColumns > 5) {
    console.error(
      `ERROR: MAX_COLUMNS IS 5 AND MIN_COLUMNS IS 1, YOU SET IT IN ${gridColumns}`
    );
  }

  return (
    <ul className={cn(styles.root, styles[`grid${gridColumns}`], classname)}>
      {children}
    </ul>
  );
};
