import { Button } from '../../Button';
import { IconProps } from '../../Icon/Icon';

import styles from './QuickAccessButton.module.scss';

interface QuickAccessButtonProps {
  text?: string;
  icon?: IconProps['type'];
  isVisible?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
}

export const QuickAccessButton = ({
  text,
  icon,
  isVisible = true,
  onClick = () => {},
  ariaLabel,
}: QuickAccessButtonProps) => {
  return (
    isVisible && (
      <Button
        className={styles.root}
        iconPlace="only-icon"
        icon={icon}
        text={text}
        onClick={onClick}
        aria-label={ariaLabel}
      />
    )
  );
};
