import { forwardRef } from 'react';
import { LandingBlock, LandingBlockProps } from '../LandingBlock/LandingBlock';
import { Heading, HeadingLevel } from '../../Heading/Heading';

import styles from './LandingServices.module.scss';
import { ServiceCard, ServiceCardProps } from '../../ServiceCard/ServiceCard';

interface LandingServicesProps extends LandingBlockProps {
  title?: string;
  headingLevel?: HeadingLevel;
  serviceCards?: ServiceCardProps[];
}

const mock: ServiceCardProps[] = [
  {
    title: 'Service 1',
    text: 'This is my service number one',
    button1: {
      text: 'See more',
      btnStyle: 'tertiary',
    },
    button2: {
      text: 'Book an Apointment',
    },
  },
  {
    title: 'Service 2',
    text: 'This is my service number one',
    button1: {
      text: 'See more',
      btnStyle: 'tertiary',
    },
    button2: {
      text: 'Book an Apointment',
    },
  },
];

export const LandingServices = forwardRef<HTMLElement, LandingServicesProps>(
  (
    {
      headingLevel = 'h2',
      title = 'Put here your title',
      serviceCards = mock,
      ...props
    },
    ref
  ) => {
    const renderServiceCards = () => {
      return serviceCards?.map((sc, index) => (
        <ServiceCard
          key={(sc.text, sc.title)}
          {...sc}
          align={index % 2 === 0 ? 'start' : 'end'}
        />
      ));
    };

    return (
      <LandingBlock classname={styles.block} {...props} ref={ref}>
        <>
          <Heading className={styles.title} headingLevel={headingLevel}>
            {title}
          </Heading>
          <div className={styles['services-container']}>
            {renderServiceCards()}
          </div>
        </>
      </LandingBlock>
    );
  }
);

LandingServices.displayName = 'LandingServices';
