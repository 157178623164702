import cn from 'classnames';
import { Button } from '../../../Button/Button';

import styles from './MonthSelector.module.scss';

interface MonthSelectorProps {
  month: string;
  year: string;
  onNext?: (event: any) => void;
  onPrev?: (event: any) => void;
  minMaxYear?: [number, number];
  minMaxMonth?: [number, number];
}

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'Octuber',
  'November',
  'December',
];

export const MonthSelector = ({
  month,
  onNext = () => {},
  onPrev = () => {},
  minMaxYear,
  minMaxMonth,
  year,
}: MonthSelectorProps) => {
  return (
    <div className={styles.root}>
      <Button
        className={styles.button}
        iconPlace="only-icon"
        icon="left-arrow"
        onClick={onPrev}
        isDisabled={
          Number(month) === minMaxMonth[0] && Number(year) - 1 < minMaxYear[0]
        }
      />
      <Button
        text={`${Months[Number(Number(month) - 1)]}`}
        className={cn(styles.button, styles.month)}
      />
      <Button
        className={styles.button}
        iconPlace="only-icon"
        icon="right-arrow"
        onClick={onNext}
        isDisabled={
          Number(month) === minMaxMonth[1] && Number(year) + 1 > minMaxYear[1]
        }
      />
    </div>
  );
};
