import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import WhatISImage from '../../../assets/images/unique/PlateletRichPlasma/blood.jpg';

export const PlateletRichPlasmaParagraphs: ParagraphProps[] = [
  {
    title: 'What is platelet-rich plasma?',
    text: `Platelet-rich plasma consists of two elements: plasma, or the liquid portion of blood, and platelets, a type of blood cell that plays an important role in healing throughout the body. Platelets are well-known for their clotting abilities, but they also contain growth factors that can trigger cell reproduction and stimulate tissue regeneration or healing in the treated area. Platelet-rich plasma is simply blood that contains more platelets than normal.
    \nTo create platelet-rich plasma, clinicians take a blood sample from the patient and place it into a device called a centrifuge that rapidly spins the sample, separating out the other components of the blood from the platelets and concentrating them within the plasma.`,
    img: WhatISImage,
  },
  {
    title: 'What is a PRP injection?',
    text: `After creating platelet-rich plasma from a patient’s blood sample, that solution is injected into the target area, such as an injured knee or a tendon. In some cases, the clinician may use ultrasound to guide the injection. The idea is to increase the concentration of specific bioproteins or hormones, called growth factors, in a specific area to accelerate the healing process.`,
  },
  {
    title: 'Conditions Treated',
    text: `PRP injections are commonly used for conditions involving musculoskeletal injuries, such as tendonitis, ligament injuries, and arthritis. They may also be considered for certain types of hair loss and skin rejuvenation.`,
  },
  {
    title: 'Number of Sessions',
    text: `The number of PRP sessions needed can vary depending on the specific condition being treated. Some individuals may experience improvement with a single injection, while others may require multiple sessions.`,
  },
];
