import cn from 'classnames';
import styles from './Hour.module.scss';
import { Button } from '../../Button/Button';
import { Heading } from '../../Heading/Heading';

interface HourProps {
  hour: string;
  onClick?: () => void;
  hasMoreHours?: boolean;
  isSelected?: boolean;
}

export const Hour = ({
  hour,
  onClick,
  hasMoreHours = false,
  isSelected = false,
}: HourProps) => {
  return (
    <li className={styles.root}>
      <Button
        className={cn(styles.button, isSelected && styles.selected)}
        text={hour}
        onClick={onClick}
      >
        <>
          {hasMoreHours && (
            <Heading className={styles.heading} headingLevel="h3">
              See all from:
            </Heading>
          )}
          <div className={cn(styles.hour, !hasMoreHours && styles.unique)}>
            {hour}
          </div>
        </>
      </Button>
    </li>
  );
};

export default Hour;
