// eslint-disable-next-line import/no-extraneous-dependencies
import GoogleMap from 'google-map-react';

import { forwardRef } from 'react';
import { LandingBlock, LandingBlockProps } from '../LandingBlock/LandingBlock';

import styles from './LandingMap.module.scss';
import { MapTag } from './MapTag/MapTag';
import { Heading } from '../../Heading/Heading';

interface LandingMapProps extends LandingBlockProps {
  title?: string;
  direction?: string;
  text?: string;
  googleLink?: string;
  lat: number;
  lng: number;
}

export const LandingMap = forwardRef<HTMLElement, LandingMapProps>(
  (
    {
      direction,
      title = 'Visit Us Title',
      text,
      googleLink,
      lat,
      lng,
      ...props
    },
    ref
  ) => {
    return (
      <LandingBlock classname={styles.root} {...props} ref={ref}>
        <>
          <div className={styles.hours}>
            <Heading className={styles.title} headingLevel="h2">
              Business Hours
            </Heading>
            <ul className={styles['list-hour']}>
              <li className={styles.hour}>Mon-Fri: 9AM - 4PM</li>
              <li className={styles.hour}>Sat: 9AM - 2PM</li>
              <li className={styles.hour}>Sun: Closed</li>
            </ul>
          </div>
          <div className={styles.direction}>
            <Heading className={styles.title} headingLevel="h2">
              {title}
            </Heading>
            <div className={styles.map}>
              <GoogleMap
                bootstrapURLKeys={{
                  key: 'AIzaSyCfWXoa4HM-ApkJN9Uz7drWLZ4JISwAaY4',
                  libraries: ['places'],
                }}
                defaultCenter={{ lat: lat - 0.0005, lng: lng + 0.0005 }}
                defaultZoom={17}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  disableDoubleClickZoom: true,
                  panControl: false,
                  disableDefaultUI: true,
                  scrollwheel: false,
                }}
                draggable={false}
              >
                <MapTag
                  lat={lat + 0.0002}
                  lng={lng}
                  direction={direction}
                  googleLink={googleLink}
                />
              </GoogleMap>
            </div>
          </div>
        </>
      </LandingBlock>
    );
  }
);

LandingMap.displayName = 'LandingMap';
