import cn from 'classnames';
import styles from './NotificationCounter.module.scss';

interface NotificationCounterProps {
  number: number | string;
  classname?: string;
}

export const NotificationCounter = ({
  number,
  classname,
}: NotificationCounterProps) => {
  return <span className={cn(styles.notification, classname)}>{number}</span>;
};
