import React, { ReactElement } from 'react';
import cn from 'classnames';
import styles from './Heading.module.scss';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export type HeadingDecoration = 'none' | 'left' | 'under';

interface HeadingProps {
  headingLevel: HeadingLevel;
  children: ReactElement | string;
  className?: string;
  headingDecoration?: HeadingDecoration;
}

export const Heading = ({
  headingLevel,
  children,
  className,
  headingDecoration = 'none',
}: HeadingProps) => {
  const HL = headingLevel;

  return (
    <HL className={cn(className, styles[headingDecoration])}>{children}</HL>
  );
};
