import { Button } from '../Button';
import styles from './SocialButton.module.scss';

interface SocialButtonProps {
  socialType: 'facebook' | 'whatsapp';
  link?: string;
}

export const SocialButton = ({ socialType, link }: SocialButtonProps) => (
  <Button
    className={styles.root}
    iconPlace="only-icon"
    icon={socialType}
    alt={link}
    onClick={() => {
      window.open(link);
    }}
  />
);
