import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Day } from '../Day/Day';
import {
  GET_FIRST_DAY_OF_MONTH,
  GET_NUMBER_DAYS_IN_MONTH,
  MAX_SLOTS_IN_MONTH,
} from '../../utils/utils';

import styles from './DaySelector.module.scss';

interface DaySelectorProps {
  YYYY: string;
  MM: string;
  DD: string;
  daysNames?: [string, string, string, string, string, string, string];
  onClick?: (event: any, day: string) => void;
  selectedYYYY?: string;
  selectedMM?: string;
  minMaxYear?: [number, number];
  minMaxMonth?: [number, number];
  minMaxDay?: [number, number];
}

const DAY_NAMES_DEFAULT: DaySelectorProps['daysNames'] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export const DaySelector = ({
  YYYY,
  MM,
  DD,
  daysNames = DAY_NAMES_DEFAULT,
  onClick = (event: any, day: string) => {},
  selectedMM,
  selectedYYYY,
  minMaxYear,
  minMaxMonth,
  minMaxDay,
}: DaySelectorProps) => {
  const [days] = useState<number[]>(Array(MAX_SLOTS_IN_MONTH).fill(null));
  useEffect(() => {}, []);

  useEffect(() => {
    // console.log(days);
  }, [days]);

  const renderDays = () => {
    const numberDays = GET_NUMBER_DAYS_IN_MONTH(Number(YYYY), Number(MM) - 1);
    const firstDayOfMonth = GET_FIRST_DAY_OF_MONTH(
      Number(YYYY),
      Number(MM) - 1
    );
    const lastDayVisibleOfLastMonth =
      GET_NUMBER_DAYS_IN_MONTH(
        Number(MM) - 1 === -1 ? Number(YYYY) - 1 : Number(YYYY),
        Number(MM) - 1 === -1 ? 12 : Number(MM) - 1
      ) - firstDayOfMonth;

    return days?.map((day, index) => {
      if (index < firstDayOfMonth) {
        const d = index + lastDayVisibleOfLastMonth + 1;

        return (
          <Day
            className={cn(styles.out)}
            key={Math.random()}
            day={d}
            ariaLabel={`${MM}-${day}-${YYYY}`}
            onClick={() => {}}
            isDisabled
          />
        );
      }

      if (index >= numberDays + firstDayOfMonth) {
        const d = index - numberDays - firstDayOfMonth + 1;

        return (
          <Day
            className={cn(styles.out)}
            key={Math.random()}
            day={d}
            ariaLabel={`${MM}-${day}-${YYYY}`}
            onClick={() => {}}
            isDisabled
          />
        );
      }

      const d =
        index >= firstDayOfMonth && index < numberDays + firstDayOfMonth
          ? index - firstDayOfMonth + 1
          : null;

      const getIsDisabled = () => {
        const FIRST_YEAR_ENABLED = minMaxYear[0];
        const LAST_YEAR_ENABLED = minMaxYear[1];
        const FIRST_MONTH_ENABLED = minMaxMonth[0];
        const LAST_MONTH_ENABLED = minMaxMonth[1];
        const FIRST_DAY_ENABLED = minMaxDay[0];
        const LAST_DAY_ENABLED = minMaxDay[1];

        const DISABLED_FIRST_DAYS_IN_ACTUAL_MONTH =
          FIRST_YEAR_ENABLED === Number(YYYY) &&
          FIRST_MONTH_ENABLED === Number(MM) &&
          d < FIRST_DAY_ENABLED;

        const DISABLED_LAST_DAYS_IN_ACTUAL_MONTH =
          LAST_YEAR_ENABLED === Number(YYYY) &&
          LAST_MONTH_ENABLED === Number(MM) &&
          d > LAST_DAY_ENABLED;

        const DISABLED_FIRST_MONTHS_IN_CURRENT_YEAR =
          Number(YYYY) === FIRST_YEAR_ENABLED &&
          Number(MM) < FIRST_MONTH_ENABLED;
        const DISABLED_LAST_MONTHS_IN_CURRENT_YEAR =
          Number(YYYY) === FIRST_YEAR_ENABLED &&
          Number(MM) > LAST_MONTH_ENABLED;

        const DISABLED_FIRST_YEARS = Number(YYYY) < FIRST_YEAR_ENABLED;
        const DISABLED_LAST_YEARS = Number(YYYY) > LAST_YEAR_ENABLED;

        const DISABLED_DAYS_IN_ACTUAL_MONTH =
          DISABLED_FIRST_DAYS_IN_ACTUAL_MONTH ||
          DISABLED_LAST_DAYS_IN_ACTUAL_MONTH;

        const DISABLED_MONTHS_IN_CURRENT_YEAR =
          DISABLED_FIRST_MONTHS_IN_CURRENT_YEAR ||
          DISABLED_LAST_MONTHS_IN_CURRENT_YEAR;

        const DISABLED_YEARS = DISABLED_FIRST_YEARS || DISABLED_LAST_YEARS;

        return (
          DISABLED_DAYS_IN_ACTUAL_MONTH ||
          DISABLED_MONTHS_IN_CURRENT_YEAR ||
          DISABLED_YEARS
        );
      };

      return (
        <Day
          className={cn(
            styles.out,
            Number(DD) === d &&
              DD.length === 2 &&
              selectedMM === MM &&
              selectedYYYY === YYYY &&
              styles['selected-day']
          )}
          key={Math.random()}
          day={d}
          ariaLabel={`${MM}-${day}-${YYYY}`}
          isDisabled={getIsDisabled()}
          onClick={(event: any) => {
            onClick(event, String(d).padStart(2, '0'));
          }}
        />
      );
    });
  };

  const renderNameDays = () =>
    daysNames.map((name) => {
      return (
        <li className={styles['day-name']} key={name}>
          {name}
        </li>
      );
    });

  return (
    <ul className={styles.root}>
      {renderNameDays()}
      {renderDays()}
    </ul>
  );
};
