// import { interpolateColors } from '../../../../utils/color';
import cn from 'classnames';
import { Button } from '../../../Button/Button';
import styles from './Day.module.scss';

interface DayProps {
  day: number;
  ariaLabel: string;
  onClick: (event: any) => void;
  isDisabled?: boolean;
  className?: string;
}
// interpolateColors;
export const Day = ({
  day,
  ariaLabel,
  onClick,
  isDisabled = false,
  className,
}: DayProps) => {
  const IS_DISABLED_LABEL = 'This day is not available now';
  return (
    <li className={cn(styles.root)}>
      <Button
        className={cn(styles.button, className)}
        text={day.toString()}
        aria-label={isDisabled ? IS_DISABLED_LABEL : ariaLabel}
        alt={isDisabled ? IS_DISABLED_LABEL : ariaLabel}
        onClick={onClick}
        isDisabled={isDisabled}
      />
    </li>
  );
};
