import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import WhatISImage from '../../../assets/images/unique/PlateletRichPlasma/blood.jpg';

export const FunctionalMedicineParagraphs: ParagraphProps[] = [
  {
    title: 'What is functional medicine?',
    text: `Functional medicine is a holistic approach to healthcare that focuses on addressing the root causes of illness and promoting optimal functioning of the body. Instead of just treating symptoms, functional medicine seeks to understand and treat the underlying imbalances in the body's systems. This approach recognizes that each person is unique and that health and disease are influenced by a complex interplay of genetic, environmental, and lifestyle factors.
    \n Key principles of functional medicine include:`,
    img: WhatISImage,
  },
  {
    title: 'Patient-Centered Care',
    text: `Functional medicine places a strong emphasis on the doctor-patient relationship. Practitioners spend time with their patients, listening to their histories and understanding their unique experiences, genetics, and environmental influences.`,
  },
  {
    title: 'Biochemical Individuality',
    text: `Each person is biochemically unique, and the factors influencing health and disease can vary widely from person to person. Functional medicine considers individual differences in genetics, environment, and lifestyle when assessing and treating patients.`,
  },
  {
    title: 'Addressing the Root Cause',
    text: `Instead of just alleviating symptoms, functional medicine aims to identify and address the root causes of illness. This often involves a thorough investigation into the underlying factors that contribute to dysfunction in the body's systems.`,
  },
  {
    title: 'Integrative and Personalized Medicine',
    text: `Functional medicine integrates a variety of therapeutic approaches, including conventional medicine, nutrition, exercise, stress management, and complementary therapies. Treatment plans are personalized based on the individual needs and circumstances of each patient.`,
  },
];
