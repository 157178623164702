import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import HormoneReplacement from '../../../assets/images/unique/HormoneReplacement/hormone_replacement.jpg';

export const HormoneReplacementParagraphs: ParagraphProps[] = [
  {
    title: 'What is Hormone Replacement?',
    text: `Hormone replacement therapy (HRT) is a medical treatment that involves supplementing or replacing hormones in the body, typically when the natural production of these hormones decreases. Hormones are chemical messengers that regulate various functions in the body, and their levels can change due to factors like aging, medical conditions, or surgical interventions. Hormone replacement therapy is commonly used to alleviate symptoms associated with hormonal imbalances. Here are some key points about hormone replacement therapy:`,
    img: HormoneReplacement,
  },
  {
    title: 'Purpose of Hormone Replacement Therapy',
    text: `The primary goal of hormone replacement therapy is to restore hormonal balance in the body. Hormones play a crucial role in regulating processes like metabolism, reproduction, mood, and overall well-being.`,
  },
  {
    title: 'Conditions Treated with HRT',
    text: `Hormone replacement therapy is often prescribed to manage symptoms related to menopause in women. During menopause, the production of estrogen and progesterone declines, leading to symptoms such as hot flashes, mood swings, and sleep disturbances. HRT can help alleviate these symptoms.
    \nIn men, testosterone levels may decrease with age, leading to symptoms like fatigue, reduced libido, and changes in mood. Testosterone replacement therapy can be used to address these symptoms.`,
  },
  {
    title: 'Types of Hormones Used',
    text: `The specific hormones used in replacement therapy depend on the individual's needs and the underlying condition. For menopausal women, estrogen and progesterone may be prescribed. Men with testosterone deficiency may receive testosterone replacement.`,
  },
  {
    title: 'Methods of Administration',
    text: `Hormone replacement therapy can be administered in various forms, including pills, patches, creams, gels, injections or implants (pellets). The chosen method often depends on the specific hormone being replaced and the individual's preferences or medical considerations.`,
  },
];
