import { ReactElement } from 'react';
import { ButtonProps } from '../Button/Button';

import styles from './QuickAccessButtons.module.scss';

interface QuickAccessButtonsProps {
  children?: ReactElement<ButtonProps>[] | ReactElement<ButtonProps>;
}

export const QuickAccessButtons = ({ children }: QuickAccessButtonsProps) => {
  return <ul className={styles.root}>{children}</ul>;
};
