import { ReactElement } from 'react';
import styles from './AddSidebar.module.scss';

interface AddSideBarProps {
  children: ReactElement;
  sidebarChildren: ReactElement;
}

export const AddSidebar = ({ children, sidebarChildren }: AddSideBarProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.children}>{children}</div>
      <section className={styles.sidebar}>{sidebarChildren}</section>
    </div>
  );
};
