/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '../../../interfaces/Menu.interfaces';
import styles from './NavBarItem.module.scss';
import { Icon } from '../../Icon';

interface NavBarItemProps {
  item: MenuItem;
  showNavigationWith: 'text' | 'icon' | 'text-icon';
  isNavbarInvisible?: boolean;
}

export const NavBarItem = ({
  item,
  showNavigationWith,
  isNavbarInvisible = false,
}: NavBarItemProps) => {
  const location = useLocation();
  const selectKindOfNavigation = () => {
    switch (showNavigationWith) {
      case 'icon':
        return (
          <>
            <Icon type={item.icon} alt={item.name} />
            <span className={styles.name}>{item.name}</span>
          </>
        );
      case 'text':
        return <span className={cn(styles['item-text'])}>{item.name}</span>;
      default:
        return (
          <>
            <Icon type={item.icon} alt={item.name} />
            <span className={styles.name}>{item.name}</span>
          </>
        );
    }
  };

  return (
    <li
      onClick={item.onClick}
      className={cn(
        styles.root,
        styles[showNavigationWith],
        isNavbarInvisible && styles['background-invisible'],
        location.pathname === item.endpoint && styles.active
      )}
      key={`${item.icon}-${item.name}`}
    >
      {selectKindOfNavigation()}
      {item?.notificationNumber > 0 && (
        <span className={styles.notification}>{item.notificationNumber}</span>
      )}
    </li>
  );
};
