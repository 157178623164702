/* eslint-disable react/no-unknown-property */
import { ReactElement, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { ColorPalettes } from '../../Landing/LandingBlock/LandingBlock';

import styles from './PageRootLanding.module.scss';
import { useTitle } from '../../../utils/useTitle';
import { Heading } from '../../Heading/Heading';
import { LandingNavbar } from '../../Landing/LandingHeader/LandingNavbar';
import { MenuItem } from '../../../interfaces/Menu.interfaces';
import { NavigationPath } from '../../NavigationPath/NavigationPath';
import { QuickAccessButtons } from '../../QuickAccessButtons/QuickAccessButtons';
import { GoToTopButton } from '../../QuickAccessButtons/GoToTopButton/GoToTopButton';
import { Footer } from '../../Footer/Footer';

interface PageRootLandingConfigurationProps {
  showTitle: boolean;
}

interface PageRootLandingProps {
  colorPalettes: ColorPalettes;
  children?: ReactElement;
  title: string;
  navbarItems?: MenuItem[];
  configuration?: PageRootLandingConfigurationProps;
  imageLinkPreview?: string;
  footerPalette?: ColorPalettes;
}

export const PageRootLanding = ({
  colorPalettes = 'unknown',
  children,
  title,
  configuration: { showTitle } = { showTitle: true },
  navbarItems = [],
  imageLinkPreview = '',
  footerPalette = 'unknown',
}: PageRootLandingProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useTitle(title);

  return (
    <div className={styles.root} color-palette={colorPalettes}>
      <Helmet>
        <meta property="og:title" content="Dr. Jessica Flores - Services" />
        <meta property="og:image" content={imageLinkPreview} />
      </Helmet>
      <LandingNavbar items={navbarItems} colorPalette={colorPalettes} />
      <div ref={ref} className={styles.content}>
        <QuickAccessButtons>
          <GoToTopButton containerRef={ref} />
        </QuickAccessButtons>
        <NavigationPath omitHome={false} />
        {showTitle && (
          <Heading className={styles.title} headingLevel="h2">
            {title}
          </Heading>
        )}
        {children}
        <Footer colorPalette={footerPalette} />
      </div>
    </div>
  );
};
