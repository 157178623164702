import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { PageRootLanding } from '../../../components/PageBuilder/PageRootLanding/PageRootLanding';
import { PageButtonBlock } from '../../../components/PageBuilder/PageRootLanding/PageButtonBlock/PageButtonBlock';
import { AddSidebar } from '../../../components/PageBuilder/PageRootLanding/AddSidebar/AddSidebar';
import { Paragraph } from '../../../components/PageBuilder/Paragraph/Paragraph';
import { NeuralTherapyParagraphs } from './Paragraphs';
import { OtherServices } from '../OtherServices/OtherServices';
import { JessicaArticlesCards } from '../OtherServices/JessicaArticlesCards';
import { AppointmentModal } from '../../components/AppointmentModal/AppointmentModal';
import { JESSICA_SERVICES } from '../../components/AppointmentModal/Services';

export const NeuralTherapy = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <PageRootLanding
      colorPalettes="doctor-1"
      title="Pain Control Management"
      imageLinkPreview={NeuralTherapyParagraphs[0].img}
      navbarItems={[
        {
          endpoint: '/',
          icon: 'home1',
          name: 'Go home',
          onClick: () => {
            navigate('/');
          },
        },
      ]}
    >
      <>
        <AppointmentModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          defaultService={JESSICA_SERVICES.find(
            (service) => service.value === 'Neural Therapy'
          )}
        />
        <AddSidebar
          sidebarChildren={
            <OtherServices
              title="Other services"
              articleCards={JessicaArticlesCards.filter(
                (card) => card.title !== 'Neural Therapy'
              )}
            />
          }
        >
          <>
            <PageButtonBlock
              buttons={[
                {
                  icon: 'calendar',
                  text: 'Book an appointment',
                  onClick: () => setIsOpen(true),
                },
              ]}
            />
            {NeuralTherapyParagraphs.map((p) => (
              <Paragraph {...p} key={`${p.title}-${p.text.slice(0, 20)}`} />
            ))}
          </>
        </AddSidebar>
      </>
    </PageRootLanding>
  );
};
