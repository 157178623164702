import { useEffect, useState } from 'react';
import { Heading } from '../Heading/Heading';
import styles from './ScheduleHour.module.scss';
import { Hour } from './Hour/Hour';
import { Button } from '../Button/Button';

export interface HourInfo {
  numSpace: number;
  time: string;
  enable: boolean;
}

interface ScheduleHourProps {
  day?: string;
  hours?: HourInfo[];
}

export const ScheduleHour = ({ day = null, hours = [] }: ScheduleHourProps) => {
  const [appointmentDuration, setAppointmentDuration] = useState<number>(0);
  const [isInInternalValues, setIsInInternalValues] = useState<boolean>(false);
  const [internalValues, setInternalValues] = useState<string[]>([]);
  const [selectedHour, setSelectedHour] = useState<string>(null);

  useEffect(() => {
    const getAppointmentDuration = () => {
      const FirstHour = Number(hours[0].time.split(':')[0]);
      const SecondHour = Number(hours[1].time.split(':')[0]);

      const FirstMin = Number(hours[0].time.split(':')[1]);
      const SecondMin = Number(hours[1].time.split(':')[1]);

      const differenceInMinOfHour =
        (FirstHour > SecondHour
          ? FirstHour - SecondHour
          : SecondHour - FirstHour) * 60;

      const differenceInMinOfMin =
        FirstMin > SecondMin ? FirstMin - SecondMin : SecondMin - FirstMin;

      const totalDifference =
        differenceInMinOfHour > differenceInMinOfMin
          ? differenceInMinOfHour - differenceInMinOfMin
          : differenceInMinOfMin - differenceInMinOfHour;
      setAppointmentDuration(totalDifference);
    };

    getAppointmentDuration();
  }, [hours]);

  const renderHours = () => {
    if (appointmentDuration >= 60) {
      return hours.map((h) => (
        <Hour
          key={`${h.time}-${h.numSpace}`}
          hour={`${h.time.split(':').slice(0, 2).join(':')} -\n${h.time
            .split(':')
            .slice(0, 1)
            .concat(
              String(Number(h.time.split(':')[1]) + appointmentDuration - 1)
            )
            .join(':')}`}
        />
      ));
    }

    const groupedItems = hours.reduce((prev, curr) => {
      const currKey = curr.time.split(':')[0];
      if (
        Object.keys(prev).some((value) => value === curr.time.split(':')[0])
      ) {
        const newValue = {
          ...prev,
          [currKey]: [...prev[currKey], ...(curr.enable ? [curr.time] : [])],
        };

        return newValue;
      }

      const newValue = {
        ...prev,
        [currKey]: [...(curr.enable ? [curr.time] : [])],
      };
      return newValue;
    }, {} as any);
    const finalEntries: [string, any][] = Object.entries(groupedItems).sort(
      (a, b) => Number(a[0]) - Number(b[0])
    );

    if (isInInternalValues) {
      return internalValues?.map((i) => (
        <Hour
          key={`${i}`}
          hour={`${i.split(':').splice(0, 2).join(':')} - ${i
            .split(':')
            .splice(0, 1)
            .concat(String(Number(i.split(':')[1]) + appointmentDuration - 1))
            .join(':')}`}
          hasMoreHours={false}
          isSelected={selectedHour === i}
          onClick={() => setSelectedHour(i)}
        />
      ));
    }

    return finalEntries.map(
      (h) =>
        h[1].length > 0 && (
          <Hour
            key={`${h[0]}`}
            hour={
              h[1].length === 1
                ? `${(h[1][0] as string).split(':').slice(0, 2).join(':')} - ${(
                    h[1][0] as string
                  )
                    .split(':')
                    .slice(0, 1)
                    .concat(
                      String(
                        Number(h[1][0].split(':')[1]) + appointmentDuration - 1
                      )
                    )
                    .join(':')}`
                : `${h[0]}:00 - ${h[0]}:59`
            }
            hasMoreHours={h[1].length > 1}
            onClick={() => {
              if (h[1].length > 1) {
                setIsInInternalValues(true);
                setInternalValues([...h[1]]);
                return;
              }

              setSelectedHour(h[1][0]);
            }}
            isSelected={(h[1] as string[]).some((val) => val === selectedHour)}
          />
        )
    );
  };

  return (
    <div className={styles.root}>
      <Heading className={styles.day} headingLevel="h3">
        May 23th of 2024
      </Heading>
      <div className={styles.head}>
        <Button
          className={styles['back-button']}
          onClick={() => {
            setIsInInternalValues(false);
          }}
          icon="left-arrow"
          iconPlace="only-icon"
        />
        <p className={styles.heading}>General Consult (30 min)</p>
      </div>
      <ul className={styles.body}>{renderHours()}</ul>
    </div>
  );
};
