import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../Button/Button';

import styles from './YearSelector.module.scss';

interface MonthSelectorProps {
  setYear: Dispatch<SetStateAction<string>>;
  year: string;
  onPrev?: () => void;
  onNext?: () => void;
  minMaxYear?: [number, number];
}

export const YearSelector = ({
  setYear,
  year,
  onPrev,
  onNext,
  minMaxYear,
}: MonthSelectorProps) => {
  return (
    <div className={styles.root}>
      <Button
        className={styles.button}
        iconPlace="only-icon"
        icon="left-arrow"
        onClick={onPrev}
        isDisabled={Number(year) - 1 < minMaxYear[0]}
      />
      <div className={styles.month}>{year}</div>
      <Button
        className={styles.button}
        iconPlace="only-icon"
        icon="right-arrow"
        onClick={onNext}
        isDisabled={Number(year) + 1 > minMaxYear[1]}
      />
    </div>
  );
};
