/* eslint-disable prefer-regex-literals */
import { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue, useForm } from 'react-hook-form';
import cn from 'classnames';
import { DaySelector } from './components/DaySelector/DaySelector';
import { MonthSelector } from './components/MonthSelector/MonthSelector';
import styles from './DatePicker.module.scss';
import { YearSelector } from './components/YearSelector/YearSelector';
import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { GET_NUMBER_DAYS_IN_MONTH } from './utils/utils';

interface DatePickerProps {
  register?: UseFormRegister<any>;
  setValue?: UseFormSetValue<any>;
  name?: string;
  firstDateAvailable?: Date;
  defaultDate?: Date;
  lastDayAvailable?: Date;
}

interface DatePickerForm {
  DD: string;
  MM: string;
  YYYY: string;
}

export const DatePicker = ({
  register,
  setValue,
  name,
  defaultDate = new Date(),
  firstDateAvailable = new Date('02/05/2024'),
  lastDayAvailable = new Date('03/06/2024'),
}: DatePickerProps) => {
  register(name);
  // Start Date states
  const [DD, setDD] = useState<string>('');
  const [MM, setMM] = useState<string>('');
  const [YYYY, setYYYY] = useState<string>('');
  // End Date states

  const [maxDayInMonth, setMaxDayInMonth] = useState<number>(31); // Max Day in current month

  // Start Default parsing date
  const DEFAULT_YEAR = String(defaultDate.getFullYear());
  const DEFAULT_MONTH = String(defaultDate.getMonth() + 1).padStart(2, '0');
  const DEFAULT_DAY = String(defaultDate.getDate()).padStart(2, '0');
  // End Default parsing date

  // Start first date available parsing
  const FIRST_DATE_AVAILABLE_YEAR = firstDateAvailable.getFullYear();
  const FIRST_DATE_AVAILABLE_MONTH = firstDateAvailable.getMonth() + 1;
  const FIRST_DATE_AVAILABLE_DAY = firstDateAvailable.getDate();
  // End first date available parsing

  // Start Last Day Available
  const LAST_DATE_AVAILABLE_YEAR = lastDayAvailable.getFullYear();
  const LAST_DATE_AVAILABLE_MONTH = lastDayAvailable.getMonth() + 1;
  const LAST_DATE_AVAILABLE_DAY = lastDayAvailable.getDate();
  // End Last Day Available

  const {
    register: registerInternal,
    setValue: setInternalValue,
    getValues: getInternalValues,
    setFocus,
  } = useForm<DatePickerForm>();

  // const getIsDisabled = () => {
  //   const FIRST_YEAR_ENABLED = FIRST_DATE_AVAILABLE_YEAR;
  //   const LAST_YEAR_ENABLED = LAST_DATE_AVAILABLE_YEAR;
  //   const FIRST_MONTH_ENABLED = FIRST_DATE_AVAILABLE_MONTH;
  //   const LAST_MONTH_ENABLED = LAST_DATE_AVAILABLE_MONTH;
  //   const FIRST_DAY_ENABLED = FIRST_DATE_AVAILABLE_DAY;
  //   const LAST_DAY_ENABLED = LAST_DATE_AVAILABLE_DAY;

  //   const DISABLED_FIRST_DAYS_IN_ACTUAL_MONTH =
  //     FIRST_YEAR_ENABLED === Number(YYYY) &&
  //     FIRST_MONTH_ENABLED === Number(MM) &&
  //     Number(DD) < FIRST_DAY_ENABLED;

  //   const DISABLED_LAST_DAYS_IN_ACTUAL_MONTH =
  //     LAST_YEAR_ENABLED === Number(YYYY) &&
  //     LAST_MONTH_ENABLED === Number(MM) &&
  //     Number(DD) > LAST_DAY_ENABLED;

  //   const DISABLED_FIRST_MONTHS_IN_CURRENT_YEAR =
  //     Number(YYYY) === FIRST_YEAR_ENABLED && Number(MM) < FIRST_MONTH_ENABLED;
  //   const DISABLED_LAST_MONTHS_IN_CURRENT_YEAR =
  //     Number(YYYY) === FIRST_YEAR_ENABLED && Number(MM) > LAST_MONTH_ENABLED;

  //   const DISABLED_FIRST_YEARS = Number(YYYY) < FIRST_YEAR_ENABLED;
  //   const DISABLED_LAST_YEARS = Number(YYYY) > LAST_YEAR_ENABLED;

  //   const DISABLED_DAYS_IN_ACTUAL_MONTH =
  //     DISABLED_FIRST_DAYS_IN_ACTUAL_MONTH || DISABLED_LAST_DAYS_IN_ACTUAL_MONTH;

  //   const DISABLED_MONTHS_IN_CURRENT_YEAR =
  //     DISABLED_FIRST_MONTHS_IN_CURRENT_YEAR ||
  //     DISABLED_LAST_MONTHS_IN_CURRENT_YEAR;

  //   const DISABLED_YEARS = DISABLED_FIRST_YEARS || DISABLED_LAST_YEARS;

  //   return (
  //     DISABLED_DAYS_IN_ACTUAL_MONTH ||
  //     DISABLED_MONTHS_IN_CURRENT_YEAR ||
  //     DISABLED_YEARS
  //   );
  // };

  useEffect(() => {
    const MAX_DAY = GET_NUMBER_DAYS_IN_MONTH(
      YYYY === '' ? 2024 : Number(YYYY),
      MM === '' ? 0 : Number(MM) - 1
    );
    setMaxDayInMonth(MAX_DAY);

    if (Number(DD) > MAX_DAY && MM.length === 2 && YYYY.length === 4) {
      const realMaxDay =
        MAX_DAY > LAST_DATE_AVAILABLE_DAY ? LAST_DATE_AVAILABLE_DAY : MAX_DAY;
      setInternalValue('DD', String(realMaxDay));
      setDD(String(realMaxDay));
    }
    setValue(
      name,
      `${getInternalValues('DD')}/${getInternalValues(
        'MM'
      )}/${getInternalValues('YYYY')}`
    );
  }, [DD, MM, YYYY, setInternalValue, setValue, getInternalValues, name]);

  const evalDateInterval = (
    dateToEval: string,
    maxDateLength: number,
    min: number,
    max: number,
    formName: 'DD' | 'MM' | 'YYYY',
    onChangeVariation?: () => void
  ) => {
    const setPrevValue = (internalFormName: typeof formName, value: string) => {
      switch (internalFormName) {
        case 'DD':
          setDD(value);
          break;
        case 'MM':
          setMM(value);
          break;
        case 'YYYY':
          setYYYY(value);
          break;
      }
    };

    const getPrevValue = (internalFormName: typeof formName) => {
      switch (internalFormName) {
        case 'DD':
          return DD;
        case 'MM':
          return MM;
        case 'YYYY':
          return YYYY;
        default:
          return '';
      }
    };

    const getNextFocus = (internalFormName: typeof formName) => {
      switch (internalFormName) {
        case 'DD':
          return 'MM';
        case 'MM':
          return 'YYYY';
        case 'YYYY':
          return 'YYYY';
        default:
          return 'DD';
      }
    };

    const dateLength = dateToEval.length;
    const DATE_NUMBER = Number(dateToEval);

    if (
      (Number(dateToEval) < 1 && dateLength === maxDateLength) ||
      isNaN(DATE_NUMBER)
    ) {
      setInternalValue(formName, getPrevValue(formName));
      return;
    }

    if (maxDateLength === dateLength) {
      setFocus(getNextFocus(formName));
    }

    if (Number(dateToEval) < min && dateLength === maxDateLength) {
      setInternalValue(formName, String(min));
      setPrevValue(formName, String(min));
      return;
    }

    if (Number(dateToEval) > max && dateLength === maxDateLength) {
      setInternalValue(formName, String(max));
      setPrevValue(formName, String(max));
      return;
    }

    if (dateLength > maxDateLength) {
      setInternalValue(formName, getPrevValue(formName));
      setPrevValue(formName, getPrevValue(formName));

      if (getNextFocus(formName) !== formName) {
        setFocus(getNextFocus(formName));
        setInternalValue(getNextFocus(formName), dateToEval[dateLength - 1]);
        setPrevValue(getNextFocus(formName), dateToEval[dateLength - 1]);
      }

      return;
    }
    onChangeVariation?.();
    setPrevValue(formName, dateToEval);
  };

  // const getMaxDayInMonth = () => {
  //   const getIsSame = (first: string, second: number, def: string) => {
  //     return first !== '' ? Number(first) === second : Number(def) === second;
  //   };

  //   const isSameMonth = getIsSame(MM, LAST_DATE_AVAILABLE_MONTH, DEFAULT_MONTH);
  //   const isSameYear = getIsSame(YYYY, LAST_DATE_AVAILABLE_YEAR, DEFAULT_YEAR);

  //   return isSameMonth && isSameYear ? LAST_DATE_AVAILABLE_DAY : maxDayInMonth;
  // };

  return (
    <div className={styles.root}>
      <div className={styles['input-container']}>
        <Input
          register={registerInternal('DD', {
            onChange: (event) =>
              evalDateInterval(event.target.value, 2, 1, maxDayInMonth, 'DD'),
          })}
          name={name}
          classname={cn(styles.input, styles.dd)}
          placeholder="DD"
          type="number"
        />
        <span className={styles.slash}>/</span>
        <Input
          register={registerInternal('MM', {
            onChange: (event) =>
              evalDateInterval(event.target.value, 2, 1, 12, 'MM', () => {}),
          })}
          name={name}
          classname={cn(styles.input, styles.mm)}
          onKeyDown={(data) => {
            if (data.code === 'Backspace' && data.target.value.length <= 1) {
              setInternalValue('MM', '');
              setMM('');
              setTimeout(() => {
                setFocus('DD', { shouldSelect: true });
              }, 0);
            }
          }}
          onFocus={() => {
            if (
              getInternalValues('DD') === '' ||
              getInternalValues('DD').length < 2
            ) {
              setFocus('DD');
            }
          }}
          placeholder="MM"
        />
        <span className={styles.slash}>/</span>
        <Input
          register={registerInternal('YYYY', {
            onChange: (event) =>
              evalDateInterval(
                event.target.value,
                4,
                FIRST_DATE_AVAILABLE_YEAR,
                LAST_DATE_AVAILABLE_YEAR,
                'YYYY'
              ),
          })}
          name={name}
          classname={cn(styles.input, styles.yyyy)}
          placeholder="YYYY"
          onKeyDown={(data) => {
            if (data.code === 'Backspace' && data.target.value.length <= 1) {
              setInternalValue('YYYY', '');
              setYYYY('');
              setTimeout(() => {
                setFocus('MM', { shouldSelect: true });
              }, 0);
            }
          }}
          onFocus={() => {
            if (
              getInternalValues('MM') === '' ||
              getInternalValues('MM').length < 2
            ) {
              setFocus('MM');
            }

            if (
              getInternalValues('DD') === '' ||
              getInternalValues('DD').length < 2
            ) {
              setFocus('DD');
            }
          }}
        />
        <Button
          className={styles['date-button']}
          iconPlace="only-icon"
          icon="calendar"
        />
      </div>
      <div className={styles.date}>
        <YearSelector
          onPrev={() => {
            setYYYY((y) => {
              const newYear =
                y !== ''
                  ? String(Number(y) - 1)
                  : String(Number(DEFAULT_YEAR) - 1);
              return newYear;
            });
          }}
          onNext={() => {
            setYYYY((y) => {
              const newYear =
                y !== ''
                  ? String(Number(y) + 1)
                  : String(Number(DEFAULT_YEAR) + 1);
              return newYear;
            });
          }}
          year={YYYY !== '' && YYYY.length === 4 ? YYYY : DEFAULT_YEAR}
          setYear={setYYYY}
          minMaxYear={[FIRST_DATE_AVAILABLE_YEAR, LAST_DATE_AVAILABLE_YEAR]}
        />
        <MonthSelector
          month={MM !== '' && MM.length === 2 ? MM : DEFAULT_MONTH}
          year={YYYY !== '' && YYYY.length === 4 ? YYYY : DEFAULT_YEAR}
          minMaxYear={[FIRST_DATE_AVAILABLE_YEAR, LAST_DATE_AVAILABLE_YEAR]}
          minMaxMonth={[FIRST_DATE_AVAILABLE_MONTH, LAST_DATE_AVAILABLE_MONTH]}
          onNext={() => {
            if (
              (Number(MM) + 1 === 13 && MM !== '') ||
              (Number(DEFAULT_MONTH) + 1 === 13 && MM === '')
            ) {
              setMM('01');
              setYYYY((y) =>
                y !== '' && y.length === 4
                  ? String(Number(y) + 1)
                  : String(Number(DEFAULT_YEAR) + 1)
              );
            } else {
              setMM((m) =>
                m !== '' && m.length === 2
                  ? String(Number(m) + 1).padStart(2, '0')
                  : String(Number(DEFAULT_MONTH) + 1).padStart(2, '0')
              );
            }
          }}
          onPrev={() => {
            if (
              (Number(MM) - 1 === 0 && MM !== '') ||
              (Number(DEFAULT_MONTH) - 1 === 0 && MM === '')
            ) {
              setMM('12');
              setYYYY((y) =>
                y !== '' && y.length === 4
                  ? String(Number(y) - 1)
                  : String(Number(DEFAULT_YEAR) - 1)
              );
            } else {
              setMM((m) =>
                m !== '' && m.length === 2
                  ? String(Number(m) - 1).padStart(2, '0')
                  : String(Number(DEFAULT_MONTH) - 1).padStart(2, '0')
              );
            }
          }}
        />
        <DaySelector
          YYYY={YYYY !== '' && YYYY.length === 4 ? YYYY : DEFAULT_YEAR}
          MM={MM !== '' && MM.length === 2 ? MM : DEFAULT_MONTH}
          DD={DD !== '' && DD.length === 2 ? DD : DEFAULT_DAY}
          minMaxYear={[FIRST_DATE_AVAILABLE_YEAR, LAST_DATE_AVAILABLE_YEAR]}
          minMaxMonth={[FIRST_DATE_AVAILABLE_MONTH, LAST_DATE_AVAILABLE_MONTH]}
          minMaxDay={[FIRST_DATE_AVAILABLE_DAY, LAST_DATE_AVAILABLE_DAY]}
          selectedMM={getInternalValues('MM')}
          selectedYYYY={getInternalValues('YYYY')}
          onClick={(event, day) => {
            setDD(day);
            setInternalValue('DD', day);
            setMM((m) => {
              const newMonth = m !== '' && m.length === 2 ? m : DEFAULT_MONTH;
              setInternalValue('MM', newMonth);

              return newMonth;
            });

            setYYYY((y) => {
              const newYear = y !== '' && y.length === 4 ? y : DEFAULT_YEAR;
              setInternalValue('YYYY', newYear);

              return newYear;
            });
          }}
        />
      </div>
    </div>
  );
};
