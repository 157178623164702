/* eslint-disable react/no-unknown-property */
import { HTMLAttributes, ReactElement, forwardRef } from 'react';

import cn from 'classnames';
import styles from './LandingBlock.module.scss';
import { ColorSet } from '../../../utils/color';

export type ColorPalettes =
  | 'doctor-1'
  | 'doctor-2'
  | 'doctor-3'
  | 'dentist-1'
  | 'dentist-2'
  | 'dentist-3'
  | 'unknown';
export type ComponentsRadius = 'none' | 'small' | 'medium' | 'large';

export interface LandingBlockProps {
  documentTitle: string;
  children?: ReactElement;
  classname?: string;
  image?: string;
  hasIntersection?: boolean;
  style?: HTMLAttributes<HTMLElement>['style'];
  colorSet?: ColorSet;
  colorPalette?: ColorPalettes;
  componentsRadius?: ComponentsRadius;
}

export const LandingBlock = forwardRef<HTMLElement, LandingBlockProps>(
  (
    {
      documentTitle = '',
      children,
      classname,
      image,
      hasIntersection = false,
      colorSet = null,
      style,
      colorPalette = 'unknown',
      componentsRadius = 'none',
    }: LandingBlockProps,
    ref
  ) => {
    const colorSetStyle: HTMLAttributes<HTMLElement>['style'] = {
      backgroundColor: `rgb(${colorSet?.rgbStartGradient.join(', ')})`,
      color: `rgb(${colorSet?.fontColor.join(', ')})`,
    };
    const finalStyle = colorSet ? colorSetStyle : style;
    return (
      <section
        onMouseEnter={(data) => {
          document.title = documentTitle;
        }}
        color-palette={colorPalette}
        components-radius={componentsRadius}
        ref={ref}
        className={cn(styles['landing-start-block-root'], classname)}
        style={finalStyle}
      >
        {image && <img className={styles.background} src={image} alt="" />}
        {hasIntersection && <div className={styles.intersection} />}
        {children}
      </section>
    );
  }
);

LandingBlock.displayName = 'LandingBlock';
