import React, { useEffect, createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
// import { MenuItem } from './interfaces/Menu.interfaces';
import { Landing } from './Pages/Landing/Landing';
import './App.scss';
import { PlateletRichPlasma } from './Pages/Services/PlateletRichPlasma/PlateletRichPlasma';
import { StemCells } from './Pages/Services/StemCells/StemCells';
import { HormoneReplacement } from './Pages/Services/HormoneReplacement/HormoneReplacement';
import { NeuralTherapy } from './Pages/Services/NeuralTherapy/NeuralTherapy';
import { ServicePage } from './Pages/Services/ServicePage/ServicePage';
import { FunctionalMedicine } from './Pages/Services/FunctionalMedicine/FunctionalMedicine';
import { VitaminInfusion } from './Pages/Services/VitaminInfusion/VitaminInfusion';
import { BookAnAppointment } from './Pages/BookAnAppointment/BookAnAppointment';

export interface ClientDataContextProps {
  clientWidth: number;
}

export const ClientDataContext = createContext<ClientDataContextProps>({
  clientWidth: document.getElementById('root').clientWidth,
});

export const App = () => {
  // const menuItems: MenuItem[] = [
  //   {
  //     endpoint: '/',
  //     icon: 'home',
  //     name: 'Inicio',
  //   },
  // ];

  useEffect(() => {}, []);

  const switchAuth = (): React.ReactElement => {
    return (
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/services">
          <Route index element={<ServicePage />} />
          <Route
            path="/services/platelet-rich-plasma"
            element={<PlateletRichPlasma />}
          />
          <Route path="/services/stem-cells" element={<StemCells />} />
          <Route
            path="/services/hormone-replacement"
            element={<HormoneReplacement />}
          />
          <Route
            path="/services/pain-control-management"
            element={<NeuralTherapy />}
          />
          <Route
            path="/services/functional-medicine"
            element={<FunctionalMedicine />}
          />
          <Route
            path="/services/vitamin-infusion"
            element={<VitaminInfusion />}
          />
        </Route>
        <Route path="/book-an-appointment" element={<BookAnAppointment />} />
      </Routes>
    );
  };

  return switchAuth();
};
