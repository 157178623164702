import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import WhatISImage from '../../../assets/images/unique/Neural Therapy/Neural_therapy.jpg';

export const NeuralTherapyParagraphs: ParagraphProps[] = [
  {
    title: 'What is Pain Control Management?',
    text: `Pain control management is a medical treatment that involves the injection of local anesthetics or other substances into specific areas of the body known as "trigger points" or "interference fields." The goal of pain control management is to alleviate pain and dysfunction by addressing disturbances in the body's electrical and chemical communication within the nervous system.`,
    img: WhatISImage,
  },
  {
    title: 'Purpose of Pain Control Management',
    text: `Pain Control Management is aimed at addressing pain and dysfunction by targeting specific points in the body where the nervous system may be disturbed. These points, known as trigger points or interference fields, can contribute to pain and other health issues.`,
  },
  {
    title: `How it works`,
    text: `The treatment involves the injection of a local anesthetic, often combined with other substances, into these trigger points. The injection is intended to normalize the function of the autonomic nervous system, which controls involuntary bodily functions like heart rate, digestion, and respiratory rate.`,
  },
  {
    title: `Conditions Treated`,
    text: `Pain Control Management is often used to address chronic pain conditions, such as headaches, musculoskeletal pain, and nerve pain. It may also be considered for other health issues related to disturbances in the autonomic nervous system.`,
  },
  {
    title: 'Patient Experience',
    text: `Patients might feel a temporary sensation at the injection site, such as pressure or warmth. The goal is for the treatment to help reduce pain and improve overall function over time.`,
  },
];
