import { MenuItem } from '../../../interfaces/Menu.interfaces';
import { NavBar, SocialMedias } from '../../NavBar/NavBar';
import { ColorPalettes } from '../LandingBlock/LandingBlock';

import './LandingNavbar.module.scss';

interface LandingNavbarProps {
  items: MenuItem[];
  colorPalette?: ColorPalettes;
  backgroundInvisible?: boolean;
  social?: SocialMedias;
  removeBoxShadow?: boolean;
}

export const LandingNavbar = ({
  items,
  colorPalette = 'unknown',
  backgroundInvisible = false,
  removeBoxShadow = false,
  social = null,
}: LandingNavbarProps) => {
  return (
    <NavBar
      items={items}
      showNavigationWith="text"
      colorPalette={colorPalette}
      social={social}
      configuration={{
        backgroundInvisible,
        navbarOnTop: true,
        removeTitleLarge: true,
        removePadding: true,
        removeBoxShadow,
      }}
    />
  );
};
