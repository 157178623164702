import { HTMLAttributes, ReactElement, useRef } from 'react';

import { interpolateColors } from '../../../utils/color';
import styles from './LandingWrapper.module.scss';
import { MenuItem } from '../../../interfaces/Menu.interfaces';
import { ColorPalettes } from '../LandingBlock/LandingBlock';
import { LandingNavbar } from '../LandingHeader/LandingNavbar';
import { SocialMedias } from '../../NavBar/NavBar';
import { QuickAccessButtons } from '../../QuickAccessButtons/QuickAccessButtons';
import { GoToTopButton } from '../../QuickAccessButtons/GoToTopButton/GoToTopButton';
import { Footer } from '../../Footer/Footer';

export type RGB = [r: number, g: number, b: number];

interface LandingConfiguration {
  enableColorInterpolation?: boolean;
  startColorInterpolation?: RGB;
  endColorInterpolation?: RGB;
}

interface LandingWrapperProps {
  menuItems?: MenuItem[];
  children?: ReactElement[];
  navbarColorPalette?: ColorPalettes;
  footerColorPalette?: ColorPalettes;
  config?: LandingConfiguration;
  social?: SocialMedias;
}

export const LandingWrapper = ({
  children,
  menuItems = undefined,
  config: {
    enableColorInterpolation,
    startColorInterpolation,
    endColorInterpolation,
  } = {
    enableColorInterpolation: false,
    startColorInterpolation: null,
    endColorInterpolation: null,
  },
  navbarColorPalette,
  footerColorPalette,
  social,
}: LandingWrapperProps) => {
  const ref = useRef<HTMLDivElement>(undefined);
  const rgbs: RGB[] = interpolateColors(
    startColorInterpolation || [0, 0, 0],
    endColorInterpolation || [255, 255, 255],
    children.length
  );

  const finalChildren = [
    ...children.map((Item, index) => {
      if (enableColorInterpolation) {
        return {
          ...Item,
          props: {
            ...Item.props,
            style: {
              background: `linear-gradient(rgb(${rgbs[index][0]},${
                rgbs[index][1]
              },${rgbs[index][2]}), 
              rgb(${rgbs[index + 1][0]},${rgbs[index + 1][1]},${
                rgbs[index + 1][2]
              })`,
            } as HTMLAttributes<HTMLElement>['style'],
          },
        };
      }
      return Item;
    }),
  ];

  const finalItems = menuItems?.map((item) => {
    if (item.goToRef) {
      return {
        ...item,
        onClick: () => {
          ref?.current?.scrollTo({
            top:
              Number(item?.goToRef?.current?.offsetTop) +
              Number(item.offsetCorrection || 0),
            behavior: 'smooth',
          });
        },
      };
    }

    return item;
  });

  return (
    <div className={styles['landing-wrapper-root']} ref={ref}>
      <QuickAccessButtons>
        <GoToTopButton containerRef={ref} />
      </QuickAccessButtons>
      {menuItems && (
        <LandingNavbar
          items={finalItems}
          colorPalette={navbarColorPalette}
          social={social}
          removeBoxShadow
        />
      )}
      {finalChildren}
      <Footer colorPalette={footerColorPalette} />
    </div>
  );
};
