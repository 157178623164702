/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { HTMLAttributes, useState } from 'react';
import { MenuItem } from '../../interfaces/Menu.interfaces';

import styles from './NavBar.module.scss';
import { SlideMenu } from './SlideMenu/SlideMenu';
import { Button } from '../Button';
import { Logo } from '../Logo/Logo';
import LOGO_URL from '../../assets/images/unique/LogoRemastered.png';
import { NavBarItem } from './NavBarItem/NavBarItem';
import { ColorPalettes } from '../Landing/LandingBlock/LandingBlock';
import { SocialButton } from '../SocialButton/SocialButton';

interface NavBarConfiguration {
  backgroundInvisible?: boolean;
  navbarOnTop?: boolean;
  darkMode?: boolean;
  removeTitleLarge?: boolean;
  removeTitleSmall?: boolean;
  removePadding?: boolean;
  removeBoxShadow?: boolean;
  style?: HTMLAttributes<HTMLDivElement>['style'];
  linkTitle?: string;
}

export interface SocialMediaInfo {
  link: string;
}

export interface SocialMedias {
  facebook?: SocialMediaInfo;
  whatsapp?: SocialMediaInfo;
}

interface NavBarProps {
  items: MenuItem[];
  social?: SocialMedias;
  showNavigationWith?: 'text' | 'icon' | 'text-icon';
  colorPalette?: ColorPalettes;
  configuration?: NavBarConfiguration;
}

export const NavBar = ({
  items,
  showNavigationWith = 'icon',
  configuration: {
    backgroundInvisible,
    navbarOnTop,
    darkMode,
    removeTitleLarge,
    removeTitleSmall,
    removePadding,
    linkTitle,
    style,
    removeBoxShadow,
  } = {
    navbarOnTop: false,
    backgroundInvisible: false,
    darkMode: false,
    style: {},
    removeTitleSmall: false,
    removeTitleLarge: false,
    removePadding: false,
    linkTitle: '/',
    removeBoxShadow: false,
  },
  colorPalette = 'unknown',
  social = null,
}: NavBarProps) => {
  const navigate = useNavigate();
  const [isOpenSlideMenu, setIsOpenSlideMenu] = useState<boolean>(false);
  const itemQuickAccess: MenuItem[] = items.filter(
    (item) => item.shouldBeQuickAccess
  );

  const renderItemsTabletUp = () => {
    return (
      <ul className={cn(styles.items, styles['tablet-up'])}>
        {items.map((item) => (
          <NavBarItem
            key={(item.endpoint, item.name)}
            item={item}
            showNavigationWith={showNavigationWith}
            isNavbarInvisible={backgroundInvisible}
          />
        ))}
      </ul>
    );
  };

  const renderQuickAccess = () => {
    return (
      <ul className={cn(styles.items, styles['tablet-down'])}>
        {itemQuickAccess.map((item) => (
          <NavBarItem
            key={(item.endpoint, item.name)}
            item={item}
            showNavigationWith={showNavigationWith}
            isNavbarInvisible={backgroundInvisible}
          />
        ))}
      </ul>
    );
  };

  const renderItemsTabletDown = () => {
    return (
      <div className={styles['direct-buttons']}>
        <Button
          className={styles['menu-button']}
          iconPlace="only-icon"
          icon="menu"
          onClick={() => {
            setIsOpenSlideMenu(true);
          }}
        />
        {renderQuickAccess()}
      </div>
    );
  };

  return (
    <>
      <SlideMenu
        isOpen={isOpenSlideMenu}
        items={items}
        onClose={() => {
          setIsOpenSlideMenu(false);
        }}
      />
      <div
        className={cn(
          styles['new-header-client-root'],
          backgroundInvisible && styles['background-invisible'],
          removePadding && styles['remove-padding'],
          removeBoxShadow && styles['remove-box-shadow'],
          navbarOnTop && styles['on-top'],
          darkMode && styles['dark-mode']
        )}
        style={style}
        color-palette={colorPalette}
      >
        {social && (
          <div className={cn(styles.social)}>
            {social.whatsapp && (
              <SocialButton socialType="whatsapp" link={social.whatsapp.link} />
            )}
            {social.facebook && (
              <SocialButton socialType="facebook" link={social.facebook.link} />
            )}
          </div>
        )}
        <div className={styles['items-container']}>
          {renderItemsTabletDown()}
          {!removeTitleSmall && (
            <img
              onClick={() => {
                navigate(linkTitle);
              }}
              className={cn(styles.logo, styles['tablet-down'])}
              src={LOGO_URL}
              alt=""
            />
          )}
          {!removeTitleLarge && (
            <Logo
              onClick={() => {
                navigate(linkTitle);
              }}
              size="xs"
              classname={cn(styles.logo, styles['tablet-up'])}
            />
          )}
          {renderItemsTabletUp()}
        </div>
      </div>
    </>
  );
};
