/* eslint-disable react/prop-types */
import React, {
  forwardRef,
  LegacyRef,
  Ref,
  ReactElement,
  HTMLProps,
} from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Button.module.scss';
import { Icon, IconProps } from '../Icon/Icon';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  className?: string;
  text?: string;
  btnStyle?: 'primary' | 'secondary' | 'tertiary' | 'success';
  iconPlace?:
    | 'none'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'only-icon'
    | 'only-text';
  icon?: IconProps['type'];
  onClick?: (event: any) => void;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
  to?: string;
  href?: string;
  alt?: string;
  type?: 'button' | 'submit' | 'reset';
  tabIndex?: number;
  onKeyDown?: (data: any) => void;
  children?: ReactElement;
  isDisabled?: boolean;
}

export const Button = forwardRef(
  (
    {
      text,
      btnStyle = 'primary',
      onClick,
      iconPlace = 'none',
      icon = 'register',
      to,
      href,
      className,
      type,
      tabIndex,
      onKeyDown,
      alt,
      children,
      isDisabled,
      'aria-label': ariaLabel,
    }: ButtonProps,
    ref: LegacyRef<HTMLElement>
  ): React.ReactElement => {
    const renderIcon = () => {
      return (
        <>
          {iconPlace !== 'none' && (
            <div className={cn(styles.icon)}>
              <Icon alt={alt} type={icon} />
            </div>
          )}
          {iconPlace !== 'only-icon' && (
            <div className={`${styles.text}`}>{children || text}</div>
          )}
        </>
      );
    };

    const renderButton = () => {
      if (to && href)
        console.error(`You can't use 'to' and 'href' in the same tag`);
      if (to && !isDisabled)
        return (
          <Link
            className={cn(
              styles.button,
              styles[btnStyle],
              styles[iconPlace],
              isDisabled && styles.disabled,
              className
            )}
            onClick={isDisabled ? () => {} : onClick}
            ref={ref as unknown as Ref<HTMLAnchorElement> | undefined}
            to={to}
            aria-label={ariaLabel}
            aria-disabled={isDisabled}
          >
            {renderIcon()}
          </Link>
        );
      if (href && !isDisabled)
        return (
          <a
            className={cn(
              styles.button,
              styles[btnStyle],
              styles[iconPlace],
              isDisabled && styles.disabled,
              className
            )}
            onClick={isDisabled ? () => {} : onClick}
            ref={ref as unknown as Ref<HTMLAnchorElement> | undefined}
            href={href}
            aria-label={ariaLabel}
            aria-disabled={isDisabled}
          >
            {renderIcon()}
          </a>
        );

      return (
        <button
          aria-label={ariaLabel}
          tabIndex={tabIndex}
          className={cn(
            styles.button,
            styles[btnStyle],
            styles[iconPlace],
            isDisabled && styles.disabled,
            className
          )}
          aria-disabled={isDisabled}
          onClick={isDisabled ? () => {} : onClick}
          onKeyDown={onKeyDown}
          ref={ref as unknown as Ref<HTMLButtonElement> | undefined}
          // eslint-disable-next-line react/button-has-type
          type={type}
        >
          {renderIcon()}
        </button>
      );
    };

    return renderButton();
  }
);

Button.defaultProps = {
  btnStyle: 'primary',
  type: 'button',
  text: '',
  className: '',
  tabIndex: 0,
  onKeyDown: (data: any) => {},
};
Button.displayName = 'Button';
