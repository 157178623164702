import { useRef, useState } from 'react';
import { LandingWrapper } from '../../components/Landing/LandingWrapper/LandingWrapper';
import { LandingStartBlock } from '../../components/Landing/LandingStartBlock/LandingStartBlock';
import ImageJessica from '../../assets/images/unique/LogoRemastered.png';

import styles from './Landing.module.scss';
import { LandingSmallCards } from '../../components/Landing/LandingSmallCards/LandingSmallCards';
import { SmallCardProps } from '../../components/SmallCard/SmallCard';
import { LandingServices } from '../../components/Landing/LandingServices/LandingServices';
import { LandingMap } from '../../components/Landing/LandingMap/LandingMap';
import { MenuItem } from '../../interfaces/Menu.interfaces';
import { AppointmentModal } from '../components/AppointmentModal/AppointmentModal';
import { DropdownItemsProps } from '../../components/Dropdown/Dropdown';
import { JESSICA_SERVICES } from '../components/AppointmentModal/Services';
import { LandingReviews } from '../../components/Landing/LandingReviews/LandingReviews';
import { NewServiceNotification } from '../components/AppointmentModal/NewServiceNotification/NewServiceNotification';

export const Landing = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [defaultService, setDefaultService] =
    useState<DropdownItemsProps>(null);
  const serviceRef = useRef<HTMLElement>(null);
  const locationRef = useRef<HTMLElement>(null);
  const smallCards: SmallCardProps[] = [
    {
      bigText: '15+',
      smallText2: 'Years of experience',
    },
    {
      bigText: '2',
      smallText2: 'Medicine Diplomas',
    },
    {
      bigText: '5',
      smallText2: 'Services',
    },
    {
      bigText: 'UAG',
      smallText2: "Bachelor's Degree",
      theme: 'secondary',
    },
    {
      bigText: '1K+',
      smallText2: 'Happy Patients',
    },
  ];

  const menuItems: MenuItem[] = [
    {
      endpoint: '/',
      icon: 'bookmark',
      name: 'Services',
      goToRef: serviceRef,
    },
    {
      endpoint: '/location',
      icon: 'location',
      name: 'Find us!',
      goToRef: locationRef,
    },
  ];
  return (
    <LandingWrapper
      menuItems={menuItems}
      social={{
        whatsapp: {
          link: 'https://wa.me/3310504287',
        },
        facebook: {
          link: 'https://www.facebook.com/drjessicaflores',
        },
      }}
      navbarColorPalette="doctor-1"
      footerColorPalette="doctor-2"
    >
      <AppointmentModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        defaultService={defaultService}
      />
      <NewServiceNotification />
      <LandingStartBlock
        colorPalette="doctor-1"
        documentTitle="Doctor Jessica QF"
        title="Functional and Orthomolecular Medicine"
        subtitle="Discover Your Optimal Well-being"
        button1={{
          text: 'Book an appointment',
          onClick: () => {
            setIsOpen(true);
            setDefaultService(null);
          },
        }}
        button2={{
          text: 'See our services',
          to: '/services',
        }}
        localChildren={
          <img
            className={styles.image}
            src={ImageJessica}
            alt="Doctor's Logo"
          />
        }
      />
      <LandingSmallCards
        documentTitle="Experience"
        colorPalette="doctor-1"
        smallCards={smallCards}
      />
      <LandingServices
        documentTitle="Services"
        colorPalette="doctor-3"
        title="Services"
        ref={serviceRef}
        serviceCards={[
          {
            title: 'Hormone Replacement',
            text: `HRT can help relieve some of the symptoms of menopause, such as hot flashes, night sweats, vaginal dryness, mood swings, and bone loss. HRT can also lower your risk of osteoporosis, a condition that makes your bones weak and brittle.`,
            button1: {
              text: 'See more',
              to: '/services/hormone-replacement',
            },
            button2: {
              text: 'Book an appointment',
              btnStyle: 'secondary',
              onClick: () => {
                setIsOpen(true);
                setDefaultService(JESSICA_SERVICES[4]);
              },
            },
          },
          {
            title: 'Platelet Rich Plasma',
            text: `PRP is a type of treatment that uses your own blood to help your body heal faster. Your blood contains small cells called platelets, which have substances that can stimulate the growth of new cells and tissues.`,
            button1: {
              text: 'See more',
              to: '/services/platelet-rich-plasma',
            },
            button2: {
              text: 'Book an appointment',
              btnStyle: 'secondary',
              onClick: () => {
                setIsOpen(true);
                setDefaultService(JESSICA_SERVICES[2]);
              },
            },
          },
          {
            title: 'Stem Cells',
            text: `Stem cell, an undifferentiated cell that can divide to produce different cell types, like blood, bone and muscle cells. They also repair damaged tissue.`,
            button1: {
              text: 'See more',
              to: '/services/stem-cells',
            },
            button2: {
              text: 'Book an appointment',
              btnStyle: 'secondary',
              onClick: () => {
                setIsOpen(true);
                setDefaultService(JESSICA_SERVICES[3]);
              },
            },
          },
          {
            title: 'Pain Control Management',
            text: `Pain Control Management is a safe and simple procedure that usually takes only a few minutes. The injections are very shallow and cause minimal discomfort. The effects can be immediate or gradual, depending on the condition and the individual. Some people may need only one or a few sessions, while others may need more frequent or regular treatments.`,
            button1: {
              text: 'See more',
              to: '/services/pain-control-management',
            },
            button2: {
              text: 'Book an appointment',
              btnStyle: 'secondary',
              onClick: () => {
                setIsOpen(true);
                setDefaultService(JESSICA_SERVICES[5]);
              },
            },
          },
          {
            title: 'See All Services',
            button1: {
              text: 'See All',
              to: '/services',
            },
          },
        ]}
      />
      <LandingReviews colorPalette="doctor-2" />
      <LandingMap
        documentTitle="Location"
        colorPalette="doctor-2"
        ref={locationRef}
        lat={20.2994652}
        lng={-103.2562367}
        title="Visit Doctor Jessica Flores in Ajijic"
        direction="Guadalupe Victoria 101-Interior C, Centro, 45920 Ajijic, Jal."
        text="Visit us, We are near the center of Ajijic"
        googleLink="https://www.google.com/maps/place/Dr.+Jessica+Flores/@20.2994652,-103.2588116,17z/data=!3m1!4b1!4m6!3m5!1s0x842f47d22dcec52b:0xee6e8e9dfa1f1080!8m2!3d20.2994652!4d-103.2562367!16s%2Fg%2F11f5rpssp0?hl=es-419&entry=ttu"
      />
    </LandingWrapper>
  );
};

// <Icon type="medical" alt="Medical" classname={styles.icon} />
