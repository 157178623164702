import { forwardRef } from 'react';
import { LandingBlock, LandingBlockProps } from '../LandingBlock/LandingBlock';
import { SmallCard, SmallCardProps } from '../../SmallCard/SmallCard';

import styles from './LandingSmallCards.module.scss';

interface LandingSmallCardsProps extends LandingBlockProps {
  smallCards?: SmallCardProps[];
}

export const LandingSmallCards = forwardRef<
  HTMLElement,
  LandingSmallCardsProps
>(({ smallCards, ...props }, ref) => {
  const renderSmallCards = () => {
    return smallCards.map((sc, index) => (
      <SmallCard
        key={(sc.bigText, sc.smallText2)}
        {...sc}
        theme={index % 2 === 0 ? 'primary' : 'secondary'}
      />
    ));
  };
  return (
    <LandingBlock {...props} classname={styles.block} ref={ref}>
      <>{renderSmallCards()}</>
    </LandingBlock>
  );
});

LandingSmallCards.displayName = 'LandingSmallCards';
