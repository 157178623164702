import { MutableRefObject, useEffect, useState } from 'react';
import { QuickAccessButton } from '../QuickAccessButton/QuickAccessButton';

interface GoToTopButtonProps {
  containerRef: MutableRefObject<HTMLElement | HTMLDivElement> | undefined;
}

export const GoToTopButton = ({ containerRef }: GoToTopButtonProps) => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const goUp = () => {
    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const ref = containerRef.current;
    const getHeight = (event: Event) => {
      setScrollTop(ref.scrollTop);
    };
    ref.addEventListener('scroll', getHeight);

    return () => ref.removeEventListener('scroll', getHeight);
  }, []);

  return (
    <QuickAccessButton
      icon="arrow-up"
      onClick={goUp}
      isVisible={scrollTop > 500}
    />
  );
};
