/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react';

import cn from 'classnames';
import styles from './Modal.module.scss';
import { Button } from '../Button/Button';
import { ColorPalettes } from '../Landing/LandingBlock/LandingBlock';

interface ModalProps {
  isOpen: boolean;
  children?: React.ReactElement;
  className?: string;
  onClose?: () => void;
  colorPalette?: ColorPalettes;
}

export const Modal = ({
  isOpen,
  children,
  className,
  onClose,
  colorPalette = 'unknown',
}: ModalProps) => {
  const contentRef = useRef<HTMLDivElement>(undefined);
  const closeRef = useRef<HTMLElement>(undefined);
  useEffect(() => {
    if (isOpen) {
      closeRef.current.focus();
    }
  }, [isOpen]);

  const renderModal = (): React.ReactElement => {
    if (isOpen) {
      return (
        <div
          ref={contentRef}
          onClick={(event) => {
            if (event.target === contentRef.current) {
              onClose();
            }
          }}
          className={cn(
            styles['root-modal'],
            !isOpen && styles.inactive,
            className
          )}
        >
          <div className={styles.content} color-palette={colorPalette}>
            <div className={styles.header}>
              <Button
                ref={closeRef}
                className={styles.button}
                iconPlace="only-icon"
                icon="cancel"
                btnStyle="tertiary"
                onClick={onClose}
              />
            </div>
            <div className={styles.body}>{children}</div>
          </div>
        </div>
      );
    }

    return [] as unknown as React.ReactElement;
  };

  return renderModal();
};
