/* eslint-disable react/no-unknown-property */
import { useState } from 'react';
import styles from './NewServiceNotification.module.scss';
import { Button } from '../../../../components/Button/Button';

export const NewServiceNotification = () => {
  const [close, setClose] = useState<boolean>();

  return (
    !close && (
      <section color-palette="doctor-3" className={styles.root}>
        <p>{`Check out our new service: 'Vitamin Infusion'`}</p>
        <Button text="Go" to="/services/vitamin-infusion" />
        <Button
          text="Close"
          btnStyle="tertiary"
          onClick={() => setClose(true)}
        />
      </section>
    )
  );
};
