import { ParagraphProps } from '../../../components/PageBuilder/Paragraph/Paragraph';
import StemCells from '../../../assets/images/unique/StemCells/stem_cells.jpeg';

export const StemCellsParagraphs: ParagraphProps[] = [
  {
    title: 'What is Stem Cells?',
    text: `Stem cells are unique cells in the body that have the remarkable ability to develop into many different cell types, are like the body's repair team. They are unique cells that have the extraordinary ability to transform into various types of cells that the body needs. Think of them as the body's raw materials, essential for growth, maintenance, and repair.
    \nIn essence, stem cells are the body's versatile builders and repairers, quietly working behind the scenes to keep our bodies functioning properly. While there's still much to learn and discover about stem cells, their potential for helping with various health conditions holds promise for the future of medicine. If you have specific concerns or questions about stem cells and your health, it's always a good idea to talk to your healthcare provider.
    \nThere are two main types of stem cells:`,
    img: StemCells,
  },
  {
    title: 'Embryonic Stem Cells',
    text: `These are pluripotent stem cells, which means they have the potential to differentiate into any type of cell in the body, they are found in embryos, usually within the first few days after fertilization and have the ability to give rise to all the specialized cell types that make up the tissues and organs of the body.`,
  },
  {
    title: 'Adult or Somatic Stem Cells',
    text: `These are multipotent or sometimes unipotent stem cells, meaning they can differentiate into a limited range of cell types, they are found in various tissues throughout the body, including the bone marrow, skin, and brain. Adult stem cells play a key role in repairing and maintaining the tissues in which they are found.`,
  },
];
