/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-case-declarations */
import { UseFormSetValue } from 'react-hook-form';
import cn from 'classnames';
import { useState, useEffect, KeyboardEvent, useRef } from 'react';
import { Input, InputProps } from '../Input/Input';

import styles from './Dropdown.module.scss';
// import { Icon } from '../Icon/Icon';
import { Button } from '../Button/Button';
import { Icon } from '../Icon';

export interface DropdownItemsProps {
  value: string;
  placeholder: string;
}

interface DropdownProps extends Omit<InputProps, 'defaultValue'> {
  SetValue: UseFormSetValue<any>;
  items: DropdownItemsProps[];
  defaultValue?: DropdownItemsProps;
}

export const Dropdown = ({
  SetValue,
  classname,
  items,
  name,
  errorMessage,
  label,
  isRequired,
  defaultValue = undefined,
  ...inputProps
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    DropdownItemsProps | undefined
  >(defaultValue);
  const valuesContainer = useRef<HTMLUListElement>(undefined);
  const dropdownRef = useRef<HTMLButtonElement>(undefined);

  const onClick = () => {
    setIsOpen((open) => !open);
  };

  const putSelectedItem = (item: DropdownItemsProps) => {
    setSelectedItem(item);
    SetValue(name, item?.placeholder);
    setIsOpen(false);
  };

  const onKeyDownDropdown = (data: KeyboardEvent) => {
    switch (data.code) {
      case 'Enter':
        setIsOpen((open) => !open);
    }
  };

  const onKeyDownDropdownItem = (
    data: KeyboardEvent,
    item: DropdownItemsProps
  ) => {
    data.preventDefault();
    switch (data.code) {
      case 'Tab':
        setIsOpen(false);
        dropdownRef.current.focus();
        break;
      case 'Enter':
        putSelectedItem(item);
        dropdownRef.current.focus();
        break;
      case 'ArrowDown':
        if (document.activeElement.parentNode === valuesContainer.current) {
          if (document.activeElement.nextElementSibling !== null) {
            (document.activeElement.nextElementSibling as HTMLElement).focus();
          } else {
            (valuesContainer.current.firstChild as HTMLElement).focus();
          }
        } else {
          (valuesContainer.current.children[0] as HTMLElement).focus();
        }
        break;
      case 'ArrowUp':
        if (document.activeElement.parentNode === valuesContainer.current) {
          if (document.activeElement.previousSibling !== null) {
            (
              document.activeElement.previousElementSibling as HTMLElement
            ).focus();
          } else {
            (valuesContainer.current.lastChild as HTMLElement).focus();
          }
        } else {
          (valuesContainer.current.children[0] as HTMLElement).focus();
        }
    }
  };

  useEffect(() => {
    if (isOpen) {
      (valuesContainer.current.firstChild as HTMLDivElement).focus();
    }
  }, [isOpen]);

  useEffect(() => {
    SetValue(name, defaultValue?.placeholder);
  }, [defaultValue]);

  const renderItems = () => {
    return items.map((item) => {
      return (
        <li
          className={styles['item-container']}
          key={`${item.placeholder}-${Math.random()}`}
          onKeyDown={(e) => onKeyDownDropdownItem(e, item)}
          tabIndex={-1}
          onClick={() => {
            putSelectedItem(item);
          }}
        >
          <div className={styles['dropdown-item']}>{item?.placeholder}</div>
          {selectedItem?.value === item.value && (
            <Icon type="checked-thin" alt="checked" />
          )}
        </li>
      );
    });
  };

  // useEffect(() => {
  //   const onClickEvent = (event: MouseEvent) => {
  //     if (valuesContainer?.current?.children) {
  //       for (let i = 0; i < valuesContainer.current.children.length; i += 1) {
  //         if (valuesContainer.current.children[i] !== event.target) {
  //           setIsOpen(false);
  //           break;
  //         }
  //       }
  //     }
  //     console.log(event.target);

  //     console.log(event.target === valuesContainer.current);
  //   };

  //   document.addEventListener('click', onClickEvent);

  //   return () => {
  //     document.removeEventListener('click', onClickEvent);
  //   };
  // }, []);

  return (
    <div className={cn(styles.dropdown, classname)}>
      <div className={styles['dropdown-input']}>
        {label && (
          <label htmlFor={name} tabIndex={-1}>
            {label}
            {isRequired && <span className={styles.required}>*</span>}
          </label>
        )}
        <div className={styles['dropdown-container']}>
          <Input
            errorMessage={errorMessage}
            name={name}
            classname={styles.input}
            {...inputProps}
            onClick={onClick}
            readOnly
            tabIndex={-1}
            defaultValue={defaultValue?.placeholder}
          />
          <Button
            className={cn(styles.icon, isOpen && styles.open)}
            icon="right-arrow"
            iconPlace="only-icon"
            onClick={onClick}
            onKeyDown={onKeyDownDropdown}
            ref={dropdownRef}
          />
        </div>
      </div>
      {isOpen && (
        <ul ref={valuesContainer} className={styles['values-container']}>
          {renderItems()}
        </ul>
      )}
      <span>{errorMessage}</span>
    </div>
  );
};
