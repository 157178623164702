import cn from 'classnames';
import styles from './SmallCard.module.scss';

export interface SmallCardProps {
  smallText1?: string;
  bigText: string;
  smallText2?: string;
  theme?: 'primary' | 'secondary';
}

export const SmallCard = ({
  smallText1,
  bigText,
  smallText2,
  theme = 'primary',
}: SmallCardProps) => {
  return (
    <div className={cn(styles.root, styles[theme])}>
      {smallText1 && <p className={styles.p1}>{smallText1}</p>}
      <p className={styles.p2}>{bigText}</p>
      {smallText2 && <p className={styles.p3}>{smallText2}</p>}
    </div>
  );
};
