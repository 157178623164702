import { useForm } from 'react-hook-form';
import { Modal } from '../../../components/Modal/Modal';
import { Heading } from '../../../components/Heading/Heading';
import styles from './AppointmentModal.module.scss';
import { Input } from '../../../components/Input/Input';
import {
  Dropdown,
  DropdownItemsProps,
} from '../../../components/Dropdown/Dropdown';
import { Grid } from '../../../components/PageBuilder/Grid/Grid';
import { Button } from '../../../components/Button/Button';
import {
  SEND_MESSENGER_MESSAGE,
  SEND_WA_MESSAGE,
} from '../../../utils/socialLinks';
import { JESSICA_SERVICES } from './Services';
import { TextArea } from '../../../components/TextArea/TextArea';

interface AppointmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultService?: DropdownItemsProps;
}

interface AppointmentForm {
  firstName: string;
  lastName: string;
  age: string;
  service: string;
  wayOfContact: 'WhatsApp' | 'Messenger';
  context?: string;
}

const ERROR_EMPTY = 'This information is required';

export const AppointmentModal = ({
  isOpen = false,
  onClose = () => {},
  defaultService,
}: AppointmentModalProps) => {
  const message = (name: string, service: string, context: string) =>
    `Hello Dr. Jessica.[break]I'm ${name} and I would like to book an appointment for ${service}.[break]Context: ${context}`;

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
  } = useForm<AppointmentForm>();

  const onSubmit = (data: AppointmentForm) => {
    let READY = true;
    if (data.firstName === '') {
      setError('firstName', { message: ERROR_EMPTY });
      READY = false;
    }

    if (data.lastName === '') {
      setError('lastName', { message: ERROR_EMPTY });
      READY = false;
    }

    if (data.service === '') {
      setError('service', { message: ERROR_EMPTY });
      READY = false;
    }

    if (READY) {
      const MESSENGER_ID = 224230325045460;
      const TELEPHONE_NUMBER = 3310504287;
      const MESSAGE = message(
        `${data.firstName} ${data.lastName}`,
        data.service,
        data.context
      );
      console.log(data.wayOfContact);

      if (data.wayOfContact === 'WhatsApp') {
        SEND_WA_MESSAGE(TELEPHONE_NUMBER.toString(), MESSAGE);
      }

      if (data.wayOfContact === 'Messenger') {
        SEND_MESSENGER_MESSAGE(MESSENGER_ID.toString(), MESSAGE);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} colorPalette="doctor-1">
      <>
        <Heading
          className={styles.heading}
          headingLevel="h2"
          headingDecoration="left"
        >
          Book an appointment
        </Heading>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
          <Grid classname={styles.form} gridColumns={3}>
            <Input
              classname={styles.input}
              label="First Name"
              placeholder="Your Name"
              isRequired
              register={register('firstName')}
              errorMessage={errors?.firstName?.message}
            />
            <Input
              classname={styles.input}
              label="Last Name"
              placeholder="Your Last Name"
              isRequired
              register={register('lastName')}
              errorMessage={errors?.lastName?.message}
            />
            <Dropdown
              classname={styles.input}
              label="Service required"
              isRequired
              placeholder="Service to book"
              register={register('service')}
              name="service"
              errorMessage={errors?.service?.message}
              SetValue={setValue}
              items={JESSICA_SERVICES}
              defaultValue={defaultService}
            />
            <Dropdown
              label="Way Of Contact"
              isRequired
              placeholder="Way Of Contact"
              register={register('wayOfContact')}
              name="wayOfContact"
              errorMessage={errors?.wayOfContact?.message}
              SetValue={setValue}
              defaultValue={{
                placeholder: 'WhatsApp',
                value: 'WhatsApp',
              }}
              items={[
                {
                  placeholder: 'WhatsApp',
                  value: 'WhatsApp',
                },
                {
                  placeholder: 'Messenger',
                  value: 'Messenger',
                },
              ]}
            />
            <TextArea
              register={register('context')}
              name="context"
              label="Context (Optional)"
              errorMessage={errors?.context?.message}
              placeholder="Add some context"
            />
          </Grid>
          <div className={styles.buttons}>
            <Button text="Cancel" onClick={onClose} />
            <Button text="Go to book" btnStyle="secondary" type="submit" />
          </div>
        </form>
      </>
    </Modal>
  );
};
