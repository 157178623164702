import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { PageRootLanding } from '../../components/PageBuilder/PageRootLanding/PageRootLanding';
import { DatePicker } from '../../components/DatePicker/DatePicker';
import { ScheduleHour } from '../../components/ScheduleHour/ScheduleHour';
import hours from '../../components/ScheduleHour/mock-data.json';

interface ScheduleForm {
  day: string;
}

export const BookAnAppointment = () => {
  const { register, setValue, getValues, handleSubmit } =
    useForm<ScheduleForm>();
  useEffect(() => {}, [getValues]);

  const onSub = () => {
    console.log(getValues('day'));
  };
  return (
    <PageRootLanding title="Book an Appointment" colorPalettes="doctor-1">
      <form onSubmit={handleSubmit(onSub)} action="">
        <DatePicker register={register} setValue={setValue} name="day" />
        <ScheduleHour hours={hours.data} />
      </form>
    </PageRootLanding>
  );
};
