import { ReviewCardProps } from './ReviewCard/ReviewCard';

export const DrJessicaReviews: ReviewCardProps[] = [
  {
    rating: 5,
    review: `Dr Jessica is, simply put, the best. Although she treats people of all ages, 
    I can only speak for senior citizens. If you're new to the area, head for her. 
    And if you already live in the area, you no doubt already go to her.`,
  },
  {
    rating: 5,
    review: `I went to every doctor in Ajijic, a few in Guadalajara, and a couple world 
    renowned specialists in the UK and nobody could figure out the problem until I saw 
    Dr. Jessica. Its still early days in my recovery and I've warned that it'll be up and 
    down for a little while but today I left the house for more than an hour for the first time in almost 3 months. 
    Would highly recommend!`,
  },
  {
    rating: 5,
    review: `Dr. Flores is an amazing physician. Extremely competent, caring and personable. 
    She is the best!!!!! Wish I could find someone of this caliber in the states. Thank you, Dr. Jessica`,
  },
];
