import { HEX_RETURN_CAR, HEX_SPACE } from './HexChars';

export const SEND_MESSENGER_MESSAGE = (idUser: string, text: string) => {
  const finalText = replaceSpecialCharacters(text);
  window.open(`https://m.me/${idUser}?text=${finalText}`);
};

export const SEND_WA_MESSAGE = (number: string, text: string) => {
  const finalText = replaceSpecialCharacters(text);
  window.open(`https://wa.me/${number}/?text=${finalText}`);
};

export const replaceSpecialCharacters = (text: string) => {
  return text.replace(' ', HEX_SPACE).replace(/\[break\]/g, HEX_RETURN_CAR);
};
