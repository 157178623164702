import { RGB } from '../components/Landing/LandingWrapper/LandingWrapper';

export interface ColorSet {
  rgbStartGradient: RGB;
  rgbEndGradient: RGB;
  fontColor: RGB;
}

export const interpolateColors = (
  starColor: RGB,
  endColor: RGB,
  segments: number
): RGB[] => {
  const RGBs = Array.apply([0, 0, 0], Array<RGB>(segments + 1)) as RGB[];
  return RGBs.map((rgb, index) => {
    const R =
      starColor[0] * ((segments - index) / segments) +
      endColor[0] * (index / segments);
    const G =
      starColor[1] * ((segments - index) / segments) +
      endColor[1] * (index / segments);
    const B =
      starColor[2] * ((segments - index) / segments) +
      endColor[2] * (index / segments);
    return [Math.round(R), Math.round(G), Math.round(B)];
  });
};

export const BLUE_PURPLE_COLORS_SET: Omit<ColorSet, 'fontColor'> = {
  rgbStartGradient: [143, 190, 216],
  rgbEndGradient: [153, 26, 106],
};
