/* eslint-disable react/no-unknown-property */
import cn from 'classnames';
import { ButtonProps, Button } from '../Button/Button';
import { HeadingLevel, Heading } from '../Heading/Heading';
import styles from './ServiceCard.module.scss';

export interface ServiceCardProps {
  title?: string;
  text?: string;
  headingLevel?: HeadingLevel;
  button1?: ButtonProps;
  button2?: ButtonProps;
  align?: 'start' | 'end';
}

export const ServiceCard = ({
  title,
  text,
  headingLevel = 'h2',
  button1,
  button2,
  align = 'start',
}: ServiceCardProps) => {
  return (
    <div
      react-component="service-card"
      className={cn(styles.root, styles[align])}
    >
      <Heading className={styles.title} headingLevel={headingLevel}>
        {title}
      </Heading>
      <p className={styles.text}>{text}</p>
      <div className={styles.buttons}>
        {button1 && <Button {...button1} />}
        {button2 && <Button {...button2} />}
      </div>
    </div>
  );
};
