/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { Heading } from '../../../../components/Heading/Heading';
import { ArticleCard } from '../../OtherServices/OtherServices';

import styles from './ServiceCard.module.scss';

interface ServiceCardProps extends ArticleCard {
  isNew?: boolean;
  classname: string;
}

export const ServiceCard = ({
  img,
  title,
  to,
  classname,
  isNew,
}: ServiceCardProps) => {
  const navigate = useNavigate();
  return (
    <li onClick={() => navigate(to)} className={cn(styles.root, classname)}>
      <img src={img} alt="" />
      <Heading headingLevel="h3">{title}</Heading>
      {isNew && <span>New</span>}
    </li>
  );
};
