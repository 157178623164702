import { Stars } from '../Stars/Stars';
import styles from './ReviewCard.module.scss';

export interface ReviewCardProps {
  rating: number;
  review: string;
}

export const ReviewCard = ({ rating, review }: ReviewCardProps) => {
  return (
    <li className={styles.root}>
      <Stars rating={rating} />
      <p>{review}</p>
    </li>
  );
};
