/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { useNavigate } from 'react-router';
import { Heading } from '../../../components/Heading/Heading';
import styles from './OtherServices.module.scss';

export interface ArticleCard {
  img: string;
  title: string;
  to?: string;
}

interface OtherServicesProps {
  title: string;
  articleCards: ArticleCard[];
}

export const OtherServices = ({ title, articleCards }: OtherServicesProps) => {
  const navigate = useNavigate();
  const renderArticles = () => {
    return articleCards.map((article) => (
      <li
        className={cn(styles.article)}
        key={`${article.title}-${article.img}`}
        onClick={() => navigate(article?.to)}
      >
        <img loading="lazy" src={article.img} alt={article.title} />
        <Heading headingLevel="h3">{article.title}</Heading>
      </li>
    ));
  };
  return (
    <div className={styles.root}>
      <Heading
        className={styles.title}
        headingDecoration="under"
        headingLevel="h2"
      >
        {title}
      </Heading>
      <ul className={styles.articles}>{renderArticles()}</ul>
    </div>
  );
};
