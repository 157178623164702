import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Button } from '../Button';
import styles from './NavigationPath.module.scss';
import { Icon } from '../Icon/Icon';

interface CostumPath {
  name?: string;
  navigateTo?: string;
  isDisabled?: boolean;
}

interface NavigationPathProps {
  homeName?: string;
  costumPath?: CostumPath[];
  omitHome?: boolean;
  classname?: string;
}

export const NavigationPath = ({
  homeName = null,
  omitHome = true,
  costumPath = null,
  classname = '',
}: NavigationPathProps) => {
  const location = useLocation();
  const locationsName = location.pathname
    .split('/')
    .map(
      (path, index) =>
        `${path
          .split('-')
          .map(
            (item) =>
              `${
                item !== '' ? `${item[0].toUpperCase()}${item.slice(1)}` : item
              } `
          )
          .reduce((prev, acc) => `${prev} ${acc}`)}`
    )
    .map((item, index) => (index === 0 ? homeName || 'Home' : item));

  const locationPath = location.pathname
    .split('/')
    .map((path, index) => `/${path}`);

  const renderPath = () => {
    if (costumPath) {
      return costumPath.map((path, index) =>
        index < costumPath.length - 1 ? (
          <div className={styles.container} key={costumPath[index].navigateTo}>
            <Button
              className={styles.button}
              text={path.name}
              key={locationPath[index]}
              iconPlace="only-text"
              to={path.navigateTo}
              isDisabled={path.isDisabled}
              alt={`Regresar a ${path.name}`}
            />
            <Icon classname={styles.icon} type="right-arrow" alt="Next page" />
          </div>
        ) : (
          <Button
            className={styles.button}
            text={path.name}
            iconPlace="only-text"
            key={locationPath[index]}
            to={path.navigateTo}
            isDisabled={path.isDisabled}
          />
        )
      );
    }
    return locationsName
      .map((name, index) => {
        if (index >= (omitHome ? 1 : 0)) {
          return index < locationsName.length - 1 ? (
            <div className={styles.container} key={locationsName[index]}>
              <Button
                className={styles.button}
                text={name}
                key={locationPath[index]}
                iconPlace="only-text"
                to={locationPath[index]}
              />
              <Icon
                classname={styles.icon}
                type="right-arrow"
                alt="Next page"
              />
            </div>
          ) : (
            <Button
              className={styles.button}
              text={name}
              iconPlace="only-text"
              key={locationPath[index]}
            />
          );
        }

        return null;
      })
      .filter((item) => item !== null);
  };

  return <div className={cn(styles.root, classname)}>{renderPath()}</div>;
};
