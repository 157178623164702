import { ArticleCard } from './OtherServices';
import PlateletIMG from '../../../assets/images/unique/PlateletRichPlasma/blood.jpg';
import StemCellsIMG from '../../../assets/images/unique/StemCells/stem_cells.jpeg';
import HormoneReplacementIMG from '../../../assets/images/unique/HormoneReplacement/hormone_replacement.jpg';
import NeuralTherapyIMG from '../../../assets/images/unique/Neural Therapy/Neural_therapy.jpg';
import FunctionalMedicineIMG from '../../../assets/images/unique/Functional Medicine/functional-medicine-doctor.jpg';
import VitaminInfusionIMG from '../../../assets/images/unique/Vitamin Infusion/vitamin.jpeg';

export const JessicaArticlesCards: ArticleCard[] = [
  {
    title: 'Vitamin Infusion',
    img: VitaminInfusionIMG,
    to: '/services/vitamin-infusion',
  },
  {
    title: 'Hormone Replacement',
    img: HormoneReplacementIMG,
    to: '/services/hormone-replacement',
  },
  {
    title: 'Functional Medicine',
    img: FunctionalMedicineIMG,
    to: '/services/functional-medicine',
  },
  {
    title: 'Platelet Rich Plasma',
    img: PlateletIMG,
    to: '/services/platelet-rich-plasma',
  },
  {
    title: 'Stem Cells',
    img: StemCellsIMG,
    to: '/services/stem-cells',
  },
  {
    title: 'Pain Control Management',
    img: NeuralTherapyIMG,
    to: '/services/pain-control-management',
  },
];
