import { useRef } from 'react';
import { Button, ButtonProps } from '../../../Button/Button';
import styles from './PageButtonBlock.module.scss';

interface PageButtonBlockProps {
  buttons: ButtonProps[];
  tabletUpStyle?: ButtonProps['iconPlace'];
  tabletDownStyle?: ButtonProps['iconPlace'];
}

export const PageButtonBlock = ({
  buttons = [],
  tabletDownStyle = 'only-icon',
  tabletUpStyle = 'left',
}: PageButtonBlockProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const renderButtons = () =>
    buttons.map(({ iconPlace, ...props }) => (
      <Button
        key={props.text}
        {...props}
        className={styles.button}
        iconPlace="left"
      />
    ));
  return (
    <div ref={ref} className={styles.root}>
      {renderButtons()}
    </div>
  );
};
