import React, {
  HTMLInputTypeAttribute,
  MutableRefObject,
  ReactElement,
  forwardRef,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cn from 'classnames';
import styles from './Input.module.scss';

export interface InputProps {
  placeholder?: string;
  name?: string;
  register?: UseFormRegisterReturn<string> | null;
  errorMessage?: string | null;
  classname?: string;
  type?: HTMLInputTypeAttribute;
  children?: React.ReactElement;
  isRequired?: boolean;
  readOnly?: boolean;
  onChange?: (data: any) => any;
  onKeyDown?: (data: any) => any;
  onFocus?: (data: any) => any;
  onClick?: (data?: any) => any;
  tabIndex?: number;
  defaultValue?: string;
  label?: string;
  pattern?: string;
  step?: string;
}

export const Input = forwardRef(
  (
    {
      placeholder,
      name,
      register,
      errorMessage,
      classname,
      type,
      children,
      isRequired,
      readOnly,
      onChange,
      onClick,
      onKeyDown,
      onFocus,
      tabIndex,
      defaultValue,
      label,
      pattern,
      step,
    }: InputProps,
    ref: MutableRefObject<HTMLInputElement>
  ) => {
    return (
      <div
        className={cn(
          styles.root,
          errorMessage ? styles.error : undefined,
          readOnly && styles.readonly,
          classname
        )}
      >
        {label && (
          <label htmlFor={name}>
            {label}
            {isRequired && <span className={styles.required}>*</span>}
          </label>
        )}
        <input
          id={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={readOnly}
          contentEditable={!readOnly}
          onClick={onClick}
          tabIndex={tabIndex}
          defaultValue={defaultValue}
          onKeyDown={onKeyDown}
          ref={ref}
          onFocus={onFocus}
          {...register}
          pattern={pattern}
          step={step}
        />
        {children && children}
        {errorMessage && <span>{errorMessage as unknown as ReactElement}</span>}
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.defaultProps = {
  placeholder: 'value',
  classname: '',
  type: 'text',
  children: null,
  isRequired: false,
  readOnly: false,
  onChange: (data: any) => {},
  onKeyDown: (data: any) => {},
  onFocus: (data: any) => {},
  onClick: (data?: any) => {},
  tabIndex: 0,
  name: '',
  register: null,
  errorMessage: null,
  defaultValue: null,
};
