const MONTH_CODES = [0, 3, 3, 6, 1, 4, 6, 2, 5, 0, 3, 5];

export const MAX_SLOTS_IN_MONTH = 42;

export const GET_CENTURY_CODE = (YYYY: number) => {
  const CENTURY_MOD = Number(Number(YYYY).toString().slice(0, 2));
  return 6 - (CENTURY_MOD % 4) * 2;
};

export const GET_YEAR_CODE = (YYYY: number) => {
  const LAST_TWO_DIGITS = Number(Number(YYYY).toString().slice(2));

  return (LAST_TWO_DIGITS + Math.floor(LAST_TWO_DIGITS / 4)) % 7;
};

export const GET_LEAP_YEAR_CODE = (YYYY: number, MM: number) => {
  const isLeap = YYYY % 4 === 0 ? 1 : 0;
  return MM === 0 || MM === 1 ? isLeap : 0;
};

export const GET_NUMBER_DAYS_IN_MONTH = (YYYY: number, MM: number) => {
  const isLeap = GET_LEAP_YEAR_CODE(YYYY, MM);
  return (() => {
    switch (MM) {
      case 0:
        return 31;
      case 1:
        return isLeap === 1 ? 29 : 28;
      case 2:
        return 31;
      case 3:
        return 30;
      case 4:
        return 31;
      case 5:
        return 30;
      case 6:
        return 31;
      case 7:
        return 31;
      case 8:
        return 30;
      case 9:
        return 31;
      case 10:
        return 30;
      case 11:
        return 31;
      default:
        return 31;
    }
  })();
};

export const GET_FIRST_DAY_OF_MONTH = (YYYY: number, MM: number) => {
  const DAY = 1;
  return (
    (GET_YEAR_CODE(YYYY) +
      GET_CENTURY_CODE(YYYY) +
      DAY +
      MONTH_CODES[MM] -
      GET_LEAP_YEAR_CODE(YYYY, MM)) %
    7
  );
};
